// @flow
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { withHandlers } from "recompose";
import type { IMenu } from "../../../../../definitions";
import classNames from "classnames";
import { scroller } from "react-scroll";

type Type = {
  urlLinks: Array<IMenu>,
  isDark: boolean,
  onClick?: Function
};

const Component = ({ urlLinks, isDark, onClick }: Type) => {
  return (
    <div
      className={classNames("tt-menu-collapse", { "tt-submenu-dark": isDark })}
    >
      <ul className="tt-menu-nav">
        {urlLinks &&
          urlLinks.length &&
          urlLinks.map((link: IMenu, index: number) => (
            <li
              key={`${link.name}-${index}`}
              className={classNames(
                "tt-submenu-wrap tt-submenu-master",
                link.classname
              )}
            >
              {
                <Link to={link.url || "#"} onClick={link.onClick || onClick}>
                  {link.name}
                </Link>
              }
              {link.children && (
                <ul className="tt-submenu  ">
                  {link.children &&
                    link.children.length &&
                    link.children.map((child: IMenu) => (
                      <li className={child.classname} key={`${child.name}-${index}`}>
                        <Link
                          to={child.url || "#"}
                          onClick={child.onClick || onClick}
                        >
                          {child.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

Component.propTypes = {
  urlLinks: PropTypes.array,
  isDark: PropTypes.bool
};

export default withHandlers({
  onClick: () => e => {
    const anchorTag = e.currentTarget.href.split("#")[1];
    //console.log(document.getElementById(anchorTag))
    if (anchorTag && document.getElementById(anchorTag)) {
      e.preventDefault();
      scroller.scrollTo(anchorTag, {
        duration: 1500,
        delay: 100,
        smooth: true
      });
    }
  }
})(Component);
