import React from "react";
import PropTypes from "prop-types";

const Component = (props) => {
  return (
    <div className="footer-copyright">
      <p>© Femi Akinsanya Art Collection 2019 / All rights reserved</p>
      <p>Design by: <a
        href="#"
        target="_blank">whitecanvas solutions</a></p>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
