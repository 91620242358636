// @flow
import React from "react";
import { capitalize, camelCase } from "lodash";
import PropTypes from "prop-types";
import Accordion from "../../../../accordion";
import { Favorites, ArtStatusTags } from "../../../../tools";

type Type = {
  itemMaps: any,
  accordionMaps?: Array<string>,
  item: any,
  title: string,
  isAuth: any,
  showFav: boolean,
  titleMaps:any,
};

const getAccordionItems = (item: any,accordionMaps?:Array<string>,titleMaps?:{[string]:string}) =>
  accordionMaps && accordionMaps.map(title=>({title:(titleMaps && titleMaps[title]) || title,component:()=>item[title]})) || [];

const Component = ({ itemMaps, item, title, isAuth, showFav,accordionMaps,titleMaps}: Type) => {
  const itemKeys = Object.keys(itemMaps);
  return (
    <div className="gs-sidebar">
      <div className="gs-sidebar-info">
        {title && <h1 className="gs-sidebar-title">{title}</h1>}
        <div className="gallery-single-credits">
          {itemKeys &&
            itemKeys.map(
              (itemKey,index) =>
                item[itemKey] && (
                  <dl key={index}>
                    <dt>{itemMaps[itemKey]}</dt>
                    <dl>
                      <span>{item[itemKey]}</span>
                    </dl>
                  </dl>
                )
            )}
        </div>
        <Accordion items={getAccordionItems(item,accordionMaps,titleMaps)} />
      </div>
      <ul className="gallery-meta">
        <ArtStatusTags isAuth={isAuth} {...item} />
        {showFav && (
          <li>
            <Favorites isActive favCount={67} />
          </li>
        )}
      </ul>
    </div>
  );
};

Component.propTypes = {
  itemMaps: PropTypes.any,
  accordionMaps:PropTypes.any,
  titleMaps:PropTypes.any,
  item: PropTypes.any,
  isAuth: PropTypes.any,
  showFav: PropTypes.bool
};

export default Component;
