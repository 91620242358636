// @flow
import qs from "qs";
import store from "../stores";
import type { IArtistNames } from "../definitions";
import {isEmpty} from 'lodash'

const BASE_URL = process.env.CLD_IMG_BASEURL;
const VERSION = process.env.CLD_IMG_VERSION;
const TRANSFORMERS = "f_auto,q_auto,dpr_auto,w_auto,c_fill,g_auto,e_sharpen/";
const DEBOUNCE_TIME = 400;

export const normalizeImageSrc = (src: string):string=> {
  if(src){
    const parts = src.split("/");
    const len = parts.length;
    return `${parts[len - 2]}/${parts[len - 1]}`;
  }
  return src
};


export const prepareImage = (
  src: string,
  transformers?: string = TRANSFORMERS,
  useSource: boolean = false,
) => {
  const _src = !useSource ? normalizeImageSrc(src) : src;
  return BASE_URL + transformers + VERSION + _src;
};

let debouncedSetState = undefined;

export const createURL = (state: any) => `?${qs.stringify(state)}`;

export const searchStateToUrl = (location: any, searchState: any) =>
  searchState && location
    ? `${location.pathname}${createURL(searchState)}`
    : "";

export const urlToSearchState = (location: any) => {
  return qs.parse(location.search.slice(1));
};

export const getAdminToolsForContent = (
  type: string,
  isAuth: any,
  name: string = "edit",
  btnStyle: string = "btn-primary-bordered",
  ...rest: any
) => {
  const url = `/admin/#/collections/pages/entries/${type}`;
  const icon = "fa fa-user-edit";
  return isAuth
    ? { name, url, isAnchorTag: true, btnStyle, icon, ...rest }
    : null;
};

export const ART_ITEMS_MAP = {
  artist: "Artist",
  datePeriod: "Date/Period",
  irsDate: "IRSDate",
  materialMedium: "Material/Medium",
  accessionNumber: "Accession Number",
  dimensions: "Dimensions",
  technique: "Technique",
  culture: "Culture",
  originCountry: "Origin"
};

export const ART_INFO_ACCORDION_FIELDS=[
  'provenance',
  'notes',
  'exhibitionHistory',
  'publicationHistory'
]

export const ROLES = {
  any: "any",
  admin: "admin",
  member: "member"
};

export const userHasRole = (role: string): boolean => {
  const allRoles: Array<string> = store.uIStore.user
    ? store.uIStore.user.app_metadata.roles || []
    : [];
  return allRoles.includes(role);
};

export const userHasId = (id: string) => {
  return store.uIStore.user ? store.uIStore.user.id === id : false;
};

export const prepareArtistNames= (_name:string)=>{
  const name  = _name.replace(/\s\s+/g, ' ');
  const names = name && name.split(" ") || [];
  const firstname = names[0] || " ";
  const lastname = names[2] || names[1] || " ";
  const othernames = names.length > 2 && names[2] ? names[1] : " ";
  return {firstname,lastname,othernames}
};

export const getFullnames = (artist: IArtistNames): string => {
  if (artist) {
    return Object.keys(artist).reduce((acc, key) => {
      return artist[key] ? `${acc} ${artist[key]}` : acc;
    }, "");
  }
  return "";
};
