import React,{Fragment} from  "react";
import classNames from "classnames";
import { connectHitsPerPage } from "react-instantsearch-dom";
import { compose, withHandlers } from "recompose";

import PropTypes from "prop-types";

const getSelected = predicate => classNames({ "disabled selected": predicate });

const Component = ({
  items,
  currentRefinement,
  onChange,
  label,
  labelClass
}) => {
  return (
    <div className='align-center'>
      <span className='font-size-12 margin-top-10 col-lg-7 no-padding'>
        {label}
      </span>
      <div className='col-lg-5  no-padding'>
        <div>
        <select onChange={onChange} className="form-control">
          {items.map(item => (
            <option
              key={item.value}
              className={getSelected(currentRefinement === item.value)}
              value={item.value}
              selected={currentRefinement === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
        </div>
      </div>
    </div>
  );
};

Component.defaultProps = {
  label: "Results/Page:"
};

Component.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string
};

export default compose(
  connectHitsPerPage,
  withHandlers({
    onChange: ({ refine }) => event => {
      console.log(event.target.value);
      refine(event.target.value);
    }
  })
)(Component);
