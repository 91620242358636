// @flow
import React from "react";
import {observer} from 'mobx-react'
import {isMobile} from 'react-device-detect'
import PropTypes from "prop-types";
import { PageHeader, ApiTools, ArtList,Tools } from "../../components";
import { compose, withHandlers, withState, withProps } from "recompose";
import withLifeCycle from "@hocs/with-lifecycle";
import { prepareImage } from "../../util";
import store from "../../stores";
import { animateScroll } from "react-scroll";
import { connectStats } from "react-instantsearch-dom";

import withAlgoliaConnect from "../../HOC/algolia-art-connect";
import { withAuth } from "../../HOC";

const TRANSFORMERS = "f_auto,q_auto,dpr_auto,w_auto,c_fill,g_center,e_sharpen/";

const Stats = connectStats(({ processingTimeMS, nbHits }) => (
  <span>
    <i className="fa fa-chart-pie" /> &nbsp;
    {nbHits} results in {processingTimeMS}ms
  </span>
));

const Component = ({
  headerSrc,
  onItemReady,
  renderStats,
  getTools,
  isAuth,
  ...rest
}: any) => {
  return (
    <div>
      <PageHeader
        bgFadeEffect={3}
        parallaxbg={4}
        renderStats={renderStats}
        src={headerSrc}
        {...rest}
      >
        <div style={{ position: "relative", zIndex: 6 }}>
          <ApiTools.SearchInput />
        </div>
      </PageHeader>
      <section  id="gallery-list-section">
        <ApiTools.ScrollTo>
          <div className="row no-margin">
            <ApiTools.Paginator padding={1} />
              <ArtList
                isAuth={isAuth}
                getTools={getTools}
                framed={isMobile}
                modalId={'modal-desktop'}
                itemStyle={1}
                gutter={2}
                cols={4}
                onItemReady={onItemReady}
              />
          </div>
        </ApiTools.ScrollTo>
        <div className="row">
          <ApiTools.Paginator padding={1} />
        </div>
      </section>

    </div>
  );
};

Component.propTypes = {
  headerSrcPos: PropTypes.number,
  size: PropTypes.string,
  pos: PropTypes.string,
  parallaxbg: PropTypes.number,
  bgFadeEffect: PropTypes.number,
  hideImage: PropTypes.bool,
  captionSize: PropTypes.string,
  src: PropTypes.string,
  parallax: PropTypes.number,
  fadeEffect: PropTypes.number,
  description: PropTypes.string,
  wordSize: PropTypes.number,
  title: PropTypes.string,
  filters: PropTypes.string,
  onUrlChange: PropTypes.func
};

Component.defaultProps = {};

export default compose(
  withAuth("admin"),
  withHandlers({
    onUrlChange: () => url => {
      store.uIStore.collectionPath = url;
    }
  }),
  withAlgoliaConnect(process.env.ALG_INDEX_NAME),
  withState("headerSrc", "setHeaderSrc"),
  withHandlers({
    renderStats: ({ description }: any) => () => {
      return <Stats />;
    },
    getTools: ({ isAuth }: { isAuth: any }) => (artItem) => {
      return (
        <Tools.ArtStatusTags
          {...artItem}
          isAuth={isAuth}
        />
      );
    },
    onItemReady: ({
      setHeaderSrc,
      headerSrcPos
    }: {
      setHeaderSrc: Function,
      headerSrcPos: number
    }) => ({ item, itemNumber }: any) => {
      console.log(item.url)

      store.uIStore.addToNav(item.url);
      const pos = headerSrcPos || 1;
      if (itemNumber === pos) {
        const { imageId: src } = item;
        setHeaderSrc(prepareImage(src, TRANSFORMERS));
      }
    }
  }),
  withLifeCycle({
    onDidMount({
      location
    }: {
      location: { state: { scrollPos: number, elementId: string } }
    }) {
      if (location && location.state && location.state.scrollPos) {
        animateScroll.scrollTo(location.state.scrollPos);

      }
    }
  })
)(Component);
