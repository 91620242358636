import React from "react";
import {Highlight} from 'react-instantsearch-dom'
import { withHandlers } from "recompose";
import PropTypes from "prop-types";

const Component = ({ isFromSearch, onChange, value, count, label,item,currentRefinement }) => {
  return (
    label && (
      <label  className="myCustomCheck">
            {label}
         <span className='checkCount'>{count}</span>
        <input onClick={onChange} type="checkbox" checked={currentRefinement.includes(label)}  />
        <span className="checkMark" />
      </label>
    )
  );
};

Component.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  count: PropTypes.any,
  onChange: PropTypes.func,
  currentRefinement: PropTypes.array,
  item: PropTypes.any,
  isFromSearch: PropTypes.bool
};

export default withHandlers({
  onChange: ({ onChange, value }) => () => {
    onChange && onChange(value);
  }
})(Component);
