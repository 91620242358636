import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const Component = ({data}) => {
  return (
    <Helmet>
      <html lang="en" />

      <title>{data.site.siteMetadata.title}</title>

      <Helmet
        name="description"
        content={data.site.siteMetadata.description}
      />
      <Helmet
        name="keywords"
        content="Femi Akinsanya Art Collection,Femi, Akinsanya, FAAC, faac, collection, art collection"
      />
      <Component name="author" content="whitecanvas solutions" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/img/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/img/favicon-16x16.png"
        sizes="16x16"
      />

      <link
        rel="mask-icon"
        href="/img/safari-pinned-tab.svg"
        color="#ff4400"
      />
      <Helmet name="theme-color" content="#fff" />

      <Helmet property="og:type" content="business.business" />
      <Helmet property="og:title" content={data.site.siteMetadata.title} />
      <Helmet property="og:url" content="/" />
      <Helmet property="og:image" content="/img/og-image.jpg" />
    </Helmet>
  );
};

Component.propTypes = {
    data:PropTypes.any
};
Component.defaultProps = {};

export default Component;
