// @flow
import React from "react";
import PropTypes from "prop-types";
import Slider from "../../slider";
import { observer } from "mobx-react";
import { compose, withProps } from "recompose";
import Item from "../item/item-1";
import type { IArtlistItem, IuIStore } from "../../../definitions";
import Heading from "../../heading";
import store from "../../../stores";

const SLIDER_ID = "visitedSlider";

type Type = {
  data: Array<IArtlistItem>
};

const Component = ({ data, ...rest }: Type) => {
  return (
    data &&
    data.length > 0 && (
      <div className="row tt-wrap">
        <Heading heading="Recently viewed" size="sm" />
        <Slider id={SLIDER_ID} slideMargin={5} items={5}>
          {data.map((item, key) => (
            <Item   key={key}  itemStyle={1} {...item} />
          ))}
        </Slider>
      </div>
    )
  );
};

Component.propTypes = {
  items: PropTypes.array
};
Component.defaultProps = {};

export default compose(
  observer,
  withProps(({ items }: { items: Array<IArtlistItem> }) => {
    const data = items || store.uIStore.visitedItems;
    return { data };
  })
)(Component);
