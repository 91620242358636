import $ from 'jquery'
export const init=()=>{
  // =======================
  // Main menu
  // =======================

  // Add caret (little arrow down) if menu link contains dropdown
  $('.tt-submenu-wrap > a').append('<span class="tt-caret"></span>');

  // tt submenu (open submenu on hover)
  $('.tt-submenu-wrap').on("mouseenter",function(){
    $(this).addClass('tt-submenu-open');
  }).on("mouseleave",function(){
    $(this).removeClass('tt-submenu-open');
  });


  // Open/close mobile menu on toggle button click
  // ==============================================
  $('#tt-m-menu-toggle-btn').on('click',function() {
    // Add class "tt-m-menu-open" to <body> if menu is open
    $('body').toggleClass('tt-m-menu-open');

    // Mobile menu collapse
    $('.tt-menu-collapse').stop().slideToggle(300);

    // Close all dropdowns on mobile menu toggle button click
    $('.tt-submenu').slideUp(300);

    // Remove class "tt-m-dropdown-open" on dropdown toggle button click
    $('.tt-m-submenu-toggle').removeClass('tt-m-dropdown-open');
  });


  // Mobile submenu toggle button
  // =============================
  $('.tt-submenu-wrap').append('<div class="tt-m-submenu-toggle"></div>');

  // Open/close mobile dropdown on dropdown toggle button click
  $('.tt-m-submenu-toggle').on('click', function() {
    $(this).toggleClass('tt-m-dropdown-open');
    $(this).prev('ul').stop().slideToggle(300);
  });


  // Keeping sub-menus inside screen (useful if multi level sub-menus are used). Effect on large screens only!
  // More info: http://stackoverflow.com/questions/17985334/jquery-solution-for-keeping-dropdown-dropdown-inside-screen
  // ===========================
  if ($(window).width() > 768) {

    // If "<body>" contains class "tt-rtl".
    if ($('body').hasClass('tt-rtl')) {
      $('.tt-submenu-master .tt-submenu-wrap > a').parent().hover(function() {
        var menu = $('> .tt-submenu',this);
        var menupos = $(menu).offset();
        if (menupos.left < 0 ) {
          var newpos = menupos.left + $(menu).width() * 2;
          menu.css({ left: $(menu).width() });
          menu.css({ right: 'auto' });
        }
      });

    } else {

      $('.tt-submenu-master .tt-submenu-wrap > a').parent().hover(function() {
        var menu = $('> .tt-submenu',this);
        var menupos = $(menu).offset();

        if (menupos.left + menu.width() > $(window).width()) {
          var newpos = -$(menu).width();
          menu.css({ left: newpos });
        }
      });
    }
  }

}