// @flow
import type { IBlogHit } from "../../../../../../definitions";
import { preparePostImage } from "../../../../common/util";
import noImage from "../../../../../../../static/img/no-image.png";

export const converter = (hit: IBlogHit) => {
  return {
    id: hit.objectID,
    category: hit.category,
    title: hit.title,
    url: hit.url,
    publishedAt: hit.createdAt,
    publishedBy: hit.publishedBy,
    tags: hit._tags,
    summary: hit.summary,
    src: hit.summaryImgSrc?preparePostImage(hit.summaryImgSrc):noImage,
    editUrl: hit.url.replace("posts", "posts/edit"),
    isPublished:hit.isPublished
  };
};
