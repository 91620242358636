// @flow
import React from "react";
import { withState, compose, withStateHandlers } from "recompose";
import withLifeCycle from "@hocs/with-lifecycle";
import Select from "react-select/lib/Creatable";
import PropTypes from "prop-types";
import type { IAirTableApi } from "../../../../../../definitions";
import { PostDataProvider } from "../../../../../../providers/air-table";
import {
  prepareData,
  initializeData,
  SOURCE_TABLE,
  createNewData,
  prepareDataFromProvider, prepareDefaults
} from "./helpers";

type Type = {
  data: Array<any>,
  createData: Function,
  isLoading: boolean,
  isBusy: boolean,
  defaultValue:Array<any>
};

const Component = ({ data, createData, isLoading,isBusy, defaultValue,...rest }: Type) => {
  return (
    data && data.length?<div className="form-group">
      <Select
        isDisabled={isBusy || isLoading}
        isLoading={isLoading}
        onCreateOption={createData}
        isMulti
        placeholder={"select tags"}
        options={data}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>:null
  );
};

Component.propTypes = {
  provider: PropTypes.any,
  isBusy:PropTypes.bool
};
Component.defaultProps = {
  provider: PostDataProvider,
};

export default compose(
  withState("isLoading", "setLoading", false),
  withState("defaultValue", "setDefaults", []),
  withStateHandlers(
    { data: [] },
    {
      initData: ({ data }: { data: Array<any> }) => initializeData(data),
      createData: ({ data }: any, { provider, setLoading }: any) =>
        createNewData(data, provider, setLoading)
    }
  ),
  withLifeCycle({
    async onDidMount({
      provider,
      initData,
      defaults,
      setDefaults,
    }: {
      provider: IAirTableApi,
      initData: Function,
      setDefaults: Function,
      defaults:Array<string>
    }) {
      try {
        const records = await provider.list(SOURCE_TABLE);
        const result = records.map(prepareDataFromProvider);
        if (result) {
          defaults && setDefaults(prepareDefaults(result, defaults));
          initData(result);
        }
      } catch (err) {
        console.error("failed to initialize data -", err);
      }
    }
  })
)(Component);
