// @flow
import React from 'react';
import RefinementList from './components/refinement'

type TRefinement ={
  title?:string,
  subtitle?:string,
  props?:any,
}
export const prepareAccordion = ( refinements:Array<TRefinement>,onRefine?:Function)=>(
  refinements.map(refinement=>({
    title:refinement.title,
    component:()=>  <RefinementList  onRefine={onRefine}  {...refinement.props}/>
  }))
)