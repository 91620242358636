//flow
import React from "react";
import PropTypes from "prop-types";

type Type ={
  name:string,
  action?:string,
  method?:string,
  onSubmit?:Function,
  children?:any,
  className?:string,
  id?:string,
}

const Component = ({name,onSubmit,children,method,action,className,id}:Type) => {
  return (
    <form id={id} className={className} data-netlify="true" action={action} name={name} method={method} onSubmit={onSubmit}>
      <input hidden name="form-name"  value={name} />
      <div className="hide">
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" />
        </label>
      </div>
      {children}
    </form>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  method:'POST'
};

export default Component;

