import React from "react";
import PropTypes from "prop-types";

const Component = props => {
  return (
    <div className="blog-single-share">
      <ul>
        <li className="bss-text">Share:</li>
        <li>
          <a
            href="#0"
            className="btn btn-social-min btn-facebook btn-sm"
            title="Share on facebook"
          >
            <i className="fab fa-facebook-f" />
          </a>
        </li>
        <li>
          <a
            href="#0"
            className="btn btn-social-min btn-twitter btn-sm"
            title="Share on twitter"
          >
            <i className="fab fa-twitter" />
          </a>
        </li>
        <li>
          <a
            href="#0"
            className="btn btn-social-min btn-google btn-sm"
            title="Share on google+"
          >
            <i className="fab fa-google-plus-g" />
          </a>
        </li>
        <li>
          <a
            href="#0"
            className="btn btn-social-min btn-pinterest btn-sm"
            title="Share on pinterest"
          >
            <i className="fab fa-pinterest-p" />
          </a>
        </li>
        <li>
          <a
            href="#0"
            className="btn btn-social-min btn-linkedin btn-sm"
            title="Share on linkedin"
          >
            <i className="fab fa-linkedin-in" />
          </a>
        </li>
      </ul>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
