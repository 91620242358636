// @flow
import React from "react";
import PropTypes from "prop-types";
import Caption from "./components/carousel-caption";

const Component = ({ src,title, subtitle, description, actions,vPOS,hPOS,size }:any) => {
  return (
    <div className="cc-item">
      <span className="cover bg-transparent-3-dark" />
      <div
        className="cc-image bg-image background-fifty-percent"
        style={{backgroundImage:`url(${src})`}}
      />
      {title && <Caption
        title={title}
        subtitle={subtitle}
        description={description}
        actions={actions}
        size={size}
        vPOS={vPOS}
        hPOS={hPOS}
      />}
    </div>
  );
};

Component.propTypes = {
  src:PropTypes.string,
  title:PropTypes.string,
  size:PropTypes.string,
  subtitle:PropTypes.string,
  description:PropTypes.string,
  vPOS:PropTypes.string,
  hPOS:PropTypes.string,
};
Component.defaultProps = {};

export default Component;
