//flow
import React from 'react'
import BodyContent, { HTMLContent } from "../Content";

export const getBodyContent = (content: any, classname?: any) =>
  typeof content === "string" ? (
    <HTMLContent className={classname} content={content} />
  ) : (
    <BodyContent className={classname} content={content} />
  );
