// flow
import React, { Fragment } from "react";
import Helmet from "react-helmet";
import withLifeCycle from "@hocs/with-lifecycle";
import { StaticQuery, graphql } from "gatsby";
import { compose, withHandlers } from "recompose";
import Header from "../header";
import Footer from "../footer";
import { headerLinks, footerLinks, init } from "./helpers";
import { CloudinaryContext } from "cloudinary-react";
import PropTypes from "prop-types";
import { VisitedItems } from "../grid";
import AuthButton from "../../page-components/auth/auth-button";
import netlifyIdentity from "netlify-identity-widget";

import Meta from "./meta";

const cloudinaryConfig = {
  apiKey: process.env.CLD_IMG_APIKEY,
  apiSecret: process.env.CLD_IMG_SECRET,
  cloudName: process.env.CLD_IMG_NAME
};

const TemplateWrapper = ({ transparent, children, renderTools }: any) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => {
      return (
        <Fragment>
          <Meta data={data}/>
          <Header
            showLanguageMenu={false}
            transparent={transparent}
            renderTools={renderTools}
            isDark
            menuLinks={headerLinks}
          />
          <CloudinaryContext id="body-content" {...cloudinaryConfig}>
            {children}
            <div className="row" />
            <VisitedItems />
            <Footer links={footerLinks} />
          </CloudinaryContext>
        </Fragment>
      );
    }}
  />
);

TemplateWrapper.propTypes = {
  transparent: PropTypes.bool
};

export default compose(
  withHandlers({
    renderTools: () => () => <AuthButton />
  }),
  withLifeCycle({
    onDidMount() {
      // Barba.Pjax.start()
      init();
      netlifyIdentity.init();
    }
  })
)(TemplateWrapper);
