//flow
import React,{Fragment} from "react";
import {Link} from 'gatsby';
import PropTypes from "prop-types";

const logo='Femi Akinsanya Art Collection'

const Component = ({id}:any) => {
  return (
    <Fragment>
    <div className="hide-from-sm" id={id}>
      <a href="#" className="logo-dark">
        {logo}
      </a>
      <a href="#" className="logo-light">
        {logo}
      </a>

      <a href="#" className="logo-dark-m">
        {logo}
      </a>
      <a href="#" className="logo-light-m">
        {logo}
      </a>
    </div>
      <div style={{paddingTop:'2%',width:'70%',position:'absolute'}} className="hide-to-sm">
        <Link style={{ lineHeight:'50%', fontSize:'1.5em',fontWeight:900,color:'white',letterSpacing: 'normal'}} className='left-align' to="#">
          {logo}
        </Link>
      </div>
    </Fragment>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  id:'logo'
};

export default Component;