// @flow
import React from "react";
import {Link} from 'gatsby';
import PropTypes from "prop-types";
import type { IMenu } from "../../../definitions";

const Component = ({links}:{links:Array<IMenu>}) => {
  return (
    <ul className="footer-menu">
      {links && links.map((link)=>(
        <li key={link.name}><Link to={link.url || '#'}>{link.name}</Link></li>
      ))}
    </ul>
  );
};

Component.propTypes = {
  links:PropTypes.array
};
Component.defaultProps = {};

export default Component;
