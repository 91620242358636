//flow
import React from "react";
import PropTypes from "prop-types";
import withLifeCycle from "@hocs/with-lifecycle";
import {debounce} from "lodash"
import Hits from "../components/custom-hits";
import Slider from "../../../../../slider";

const ID = "blogSlider";

type Type = {
  items: number,
  slideMargin: number
};

const Component = ({ items, slideMargin, onAllDataLoaded, ...rest }: Type) => {
  const onItemReady=()=>{debounce(()=>{
    onAllDataLoaded && onAllDataLoaded()
    }, 10)
  };

  return (
    <Slider   wait={2500} slideMargin={slideMargin} id={ID} items={items} {...rest}>
      <Hits onItemReady={onItemReady}  />
    </Slider>
  );
};

Component.propTypes = {
  items:PropTypes.any,
  slideMargin:PropTypes.any,
  onDataAllLoaded:PropTypes.any,
};
Component.defaultProps = {};

export default withLifeCycle({
  onReceiveProps() {
    console.log("props received!");
  }
})(Component);
