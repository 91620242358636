// @flow
import React from "react";
import Item from "../../../../../grid/item/item-2";
import { connectHits } from "react-instantsearch-dom";
import { withHandlers, compose } from "recompose";
import { isFunction } from "lodash";
import { converter } from "./helpers";
import type { IBlogHit } from "../../../../../../definitions";
import {    removePostItem} from '../../../../common/util';

import PropTypes from "prop-types";

type Type = {
  hits: Array<IBlogHit>,
  onItemReady: Function,
  itemStyle: number,
  isAuth: any,
};

const Component = ({
  hits,
  onItemReady,
  itemStyle,
  isAuth,
}: Type) => {

  return hits.map((hit, key) => {
    return (
      <Item
        onPressRemove={()=>removePostItem(hit.objectID,hit.title)}
        navigateWithClick
        itemStyle={itemStyle}
        onItemReady={onItemReady}
        key={hit.objectID}
        isAuth={isFunction(isAuth) ? isAuth(hit) : isAuth}
        {...converter(hit)}
      />
    );
  });
};

Component.propTypes = {
  onItemReady: PropTypes.func,
  itemStyle: PropTypes.number,
  isAuth: PropTypes.bool,
};


Component.defaultProps = {};
export default compose(
  connectHits,
  withHandlers(() => {
    let itemNumber = 0;
    return {
      onItemReady: ({ onItemReady }: { onItemReady: Function }) => item => {
        itemNumber = itemNumber + 1;
        onItemReady && onItemReady({ item, itemNumber });
      }
    };
  })
)(Component);
