// @flow
import type { IAirTableApi } from "../../../../../../definitions";

export const SOURCE_TABLE = "Tags";

const prepareDataForProvider = (name: string) => {
  return { name };
};

export const prepareData = (payload: string) => ({
  value: payload,
  label: payload
});

export const prepareDataFromProvider = (record: {
  id: string,
  get: Function
}) => {
  return { value: record.id, label: record.get("name") };
};

export const initializeData = (data: Array<any>) => (payload: Array<any>) => ({
  data: [...data, ...payload]
});

export const prepareDefaults = (
  items: Array<{ value: string, label: string }>,
  defaults: Array<string>
):Array<Object> => {
  const results= items.filter(item=>defaults.includes(item.value));
  console.log(results)
  return results;
};

export const createNewData = (
  data: Array<any>,
  provider: IAirTableApi,
  setLoading: Function
) => (payload: string) => {
  setLoading(true);
  provider
    .create(SOURCE_TABLE, prepareDataForProvider(payload))
    .then(record => {
      setLoading(false);
      // console.log(prepareDataFromProvider(record))
    })
    .catch(err => {
      console.error("failed to create new data", err);
    })
    .finally(() => {
      setLoading(false);
    });
  return { data: [...data, ...[prepareData(payload)]] };
};
