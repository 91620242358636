// @flow
import React from "react";
import { Pagination } from "react-instantsearch-dom";
import HitsPerPage from "./components/custom-hits-per-page";
import PropTypes from "prop-types";
import { getHitsPerPageItems } from "./helpers";

const Component = ({
  showFirst,
  showPrevious,
  showNext,
  showLast,
  showHitsPerPage,
  ...rest
}: any) => {
  return (
    <div className="pagination-wrap">
        <Pagination
          showFirst={showFirst}
          showPrevious={showPrevious}
          showNext={showNext}
          showLast={showLast}
          {...rest}
        />

      {showHitsPerPage && <div style={{height:50}} className='position-relative padding-top-10'>
        <HitsPerPage
          defaultRefinement={20}
          items={getHitsPerPageItems([20, 40, 80, 120,150])}
        />
      </div>

      }

    </div>
  );
};

Component.propTypes = {
  showFirst: PropTypes.bool,
  showPrevious: PropTypes.bool,
  showLast: PropTypes.bool,
  showNext: PropTypes.bool,
  showHitsPerPage: PropTypes.bool,
  totalPages: PropTypes.number,
  translations: PropTypes.any,
  padding: PropTypes.any
};
Component.defaultProps = {
  showHitsPerPage:true,
  showFirst: true,
  showLast: true,
  showNext: true,
  showPrevious: true
};

export default Component;
