// @flow
import $ from 'jquery'
import {prepareImage} from '../../../../../util'
const BASEURL= process.env.CLD_IMG_BASEURL;
const VERSION= process.env.CLD_IMG_VERSION;
const TRANSFORMS = ({ width, height }) =>
  `f_auto,q_auto,b_auto,c_pad,e_sharpen,dpr_auto,${width},${height}/`;

export const prepareThumb = (src: string, w: ?any, h: ?any = 140) => {
  const height = h ? `h_${h}` : "";
  const width = w ? `w_${w}` : "";
  return prepareImage(src,TRANSFORMS({ width, height }));
};

const prepareDynamicElements = (thumbs, content) =>
  thumbs.map(thumb => ({
    src: prepareImage(thumb.src,'f_auto,q_auto,dpr_auto,w_auto,e_sharpen/',false),
    thumb: prepareThumb(thumb.src),
  }));

export const prepareContent = (title:string, artist:string) => {
  let content = "";
  content = title ? content + `${title}` : content;
  content = artist ? content + `<p>Artist: ${artist}</p>` : content;
  return content;
};

export const loadImageDisplay = (
  thumbs: Array<any>,
  content:?string,
) => {
  $("#my-image").lightGallery({
    dynamic: true,
    dynamicEl: prepareDynamicElements(thumbs,content)
  });
};
