// @flow
import React from "react";
import PropTypes from "prop-types";
import {modalClasses,modalImgClasses} from './helpers'

const FOOTER_TEXT= "FAAC 2019 / All rights reserved"

type Type ={
  title?:string,
  pos?:string,
  imgStyle?:number,
  content?:any,
  src?:string,
  id?:string,
  children?:any,
}

const Component = ({title,pos,imgStyle,children,src,id}:Type) => {
  return (
    <div id={id} className={modalClasses(pos)} tabIndex="-1" role="dialog" aria-hidden="false">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><i
              className="tt-close-btn"/></button>
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body">
            {src && <div className={modalImgClasses(imgStyle)}
                 style={{backgroundImage:`url(${src})`}}/>}
            <div className="modal-body-content">
              {children}
            </div>
          </div>
          <div className="modal-footer">
            &copy;{FOOTER_TEXT}
          </div>
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
