import React, { Component } from "react";
import PropTypes from "prop-types";

export default class JustComments extends React.Component {
  constructor(...args) {
    super(...args)
    this.ref = React.createRef()
  }
  render() {
    const{apiKey}=this.props
    return (
      <div
        ref={this.ref}
        className="just-comments"
        data-apikey={apiKey}
      />
    )
  }
  componentDidMount() {
    const s = document.createElement('script')
    s.src = '//just-comments.com/w.js'
    s.setAttribute('data-timestamp', +new Date())
    this.ref.current.appendChild(s)
  }
}

JustComments.propTypes={
  apiKey:PropTypes.string
}