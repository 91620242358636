// @flow
import React from "react";
import Hits from "../components/custom-hits";
import List from "../../../../../grid/list";

const MODAL_ID = "modalId";

type Type = {
  cols: number,
  gutter: number,
  overlay: string,
  showFilter: boolean,
  isAuth: any,
  adminUrl:string
};

const Component = ({
  cols,
  gutter,
  overlay,
  showFilter,
  isAuth,
  adminUrl
}: Type) => {
  return (
    <List
      showFilter={showFilter}
      cols={cols}
      gutter={gutter}
      overlay={overlay}
    >
        <Hits  isAuth={isAuth} adminUrl={adminUrl}  />
    </List>
  );
};



export default Component;
