// @flow
import React from "react";
import { compose, withHandlers, withState } from "recompose";
import { connectSearchBox } from "react-instantsearch-dom";
import PropTypes from "prop-types";
// import withAlgoliaConnect from "../../../../../HOC/algolia-art-connect";

const Component = ({
  placeholder,
  isSearchStalled,
  onClick,
  onRef,
  value,
  onChange,
  onKeyPress
}: any) => {
  return (
    <div>
      <div id="blog-search-form" className="form-btn-inside">
        <div className="form-group no-margin">
          <input
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            ref={onRef}
            type="text"
            className="form-control"
            id="blog-search"
            name="search"
            placeholder={placeholder}
          />
          <button onClick={onClick}>
            <i className="fas fa-search" />
          </button>
        </div>
      </div>

      {isSearchStalled ? "search is processing..." : ""}
    </div>
  );
};

Component.propTypes = {
  placeholder: PropTypes.string
};
Component.defaultProps = {
  placeholder: "Search..."
};

export default compose(
  connectSearchBox,
  withState("value", "setValue", ({ currentRefinement }) => currentRefinement),
  withHandlers(() => {
    let textInputRef;
    return {
      onRef: () => ref => {
        textInputRef = ref;
      },
      onClick: ({ refine }: any) => () => {
        refine(textInputRef.value);
      },
      onChange: ({ setValue }: any) => event => {
        setValue(event.currentTarget.value);
      },
      onKeyPress:({refine}:any)=>(e)=>{
        if(e.key==='Enter'){
          refine(textInputRef.value);
        }
      }
    };
  })
)(Component);
