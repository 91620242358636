//flow
import React from "react";
import { connectScrollTo } from 'react-instantsearch-dom';
import withLifeCycle from '@hocs/with-lifecycle';
import { compose } from 'recompose';
import {  Events,scroller } from "react-scroll";
let isScrolling = false


const SCROLL_TAG = 'algolia-scroll-to';

const Component = ({children}:any) => {
  return (
    <div id={SCROLL_TAG}>
      {children}
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  connectScrollTo,
  withLifeCycle(({value,hasNotChanged})=>({
    onDidMount(){
      Events.scrollEvent.register('begin', function(to, element) {
        isScrolling=true;
      });
      Events.scrollEvent.register('end', function(to, element) {
        isScrolling=false;
      });
    },
    onDidUpdate(props) {
      if (value !== props.value && hasNotChanged && !isScrolling) {
        scroller.scrollTo(SCROLL_TAG, {
          duration: 1000,
          delay: 100,
          smooth: true
        });
      }
    }
  }))
)(Component);
