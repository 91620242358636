import React from "react";
import {withHandlers} from 'recompose'
import PropTypes from "prop-types";

const Component = ({ onChange }) => {
  return (
    <div className="input-group  col-3">
      <span className='input-group-addon'>
        <i className="fa fa-search" />
      </span>
      <input
        onChange={onChange}
        type="search"
        className="form-control"
        name="subject"
        placeholder="Search"
        required=""
      />
    </div>
  );
};

Component.propTypes = {
  onChange:PropTypes.func
};
Component.defaultProps = {};

export default withHandlers({
  onChange:({onChange})=>(event)=>{
    onChange && onChange(event.currentTarget.value)
  }
})(Component);
