import React from "react";
import PropTypes from "prop-types";

const Component = (props) => {
  return (
    <div className="footer-text">
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
