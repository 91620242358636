import classnames from "classnames";
export const pHClasses = (size, pos) =>
  classnames(`ph-image-on ph-${pos} ph-${size}`);
export const bgClasses = (parallaxbg, bgFadeEffect, hideImage) =>
  classnames(
    `page-header-image bg-image parallax-bg-${parallaxbg} fade-out-scroll-${bgFadeEffect}`,
    {
      "hide-ph-image": hideImage
    }
  );
export const captionClasses = (captionSize, parallax, fadeEffect) =>
  classnames(
    `page-header-caption ph-caption-${captionSize} parallax-${parallax} fade-out-scroll-${fadeEffect}`
  );
