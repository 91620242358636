//flow
import React from "react";
import { Link } from "gatsby";
import { withHandlers } from "recompose";
import PropTypes from "prop-types";

const HASH = "#";

const Component = ({ title, subtitle, url, showWithLink, onClick }) => {
  const _url = url || HASH;
  return (
    <div className="gl-item-info">
      <div className="gl-item-caption">
        <span className="gl-item-category">
          <Link  onClick={onClick} to={showWithLink ? _url : HASH}>
            <div style={{overflow:'hidden',whiteSpace: 'nowrap', textoverflow:'ellipses'}}>{title}</div>
          </Link>
        </span>
        <h2 className="gl-item-title">
          <Link onClick={onClick} to={showWithLink ? _url : HASH}>
            {subtitle}
          </Link>
        </h2>
      </div>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showWithLink: PropTypes.bool,
  url: PropTypes.string
};
Component.defaultProps = {};

export default withHandlers({
  onClick: ({ showWithLink }) => e => {
    !showWithLink && e.preventDefault();
  }
})(Component);
