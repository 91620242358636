//flow
import React, { Fragment } from "react";
import { Link } from "gatsby";
import StatusTag from "../status-tag";

import PropTypes from "prop-types";

const Component = ({
  url,
  isAuth,
  classes,
  onPressRemove,
  isPublished
}: any) => {
  return isAuth ? (
    <Fragment>
      <li>
        <StatusTag
          predicate={isPublished}
          labelInactive={"unpublished"}
          labelActive={"published"}
        />
      </li>
      <li>
        <Link to={url} className={classes}>
          <i className="fa fa-edit" /> edit
        </Link>
      </li>
      <li>
        <a href={"#"} onClick={onPressRemove} className={classes}>
          <i className="fa fa-trash" /> remove
        </a>
      </li>
    </Fragment>
  ) : null;
};

Component.propTypes = {
  url: PropTypes.string,
  isAuth: PropTypes.bool,
  classes: PropTypes.string,
  isPublished: PropTypes.bool,
  onPressRemove: PropTypes.func
};
Component.defaultProps = {
  url: "#",
  classes: "bl-item-comments-count"
};

export default Component;
