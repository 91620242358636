// @flow
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import FavoriteBtn from "../../../tools/favorites";
import type { IBlog } from "../../../../definitions";
import AdminTools from "../../common/admin-tools";
import noImage from '../../../../../static/img/no-image.png'
import {formatDate} from "../../helpers";
import withLifeCycle from "@hocs/with-lifecycle/build/node-cjs/index";


const Component = ({
  url,
  src,
  title,
  category,
  summary,
  publishedAt,
  publishedBy,
  favCount,
  isAuth,
  editUrl,
  onPressRemove,
  isPublished
}: IBlog) => {
  return (
    <div  className="isotope-item">
      <article className="blog-list-item">
        {src && (
          <Link to={url} className="bl-item-image">
            <img  src={src} alt="image" />
          </Link>
        )}

        <div className="bl-item-inf ">
          <div className="bl-item-category">
            <Link to="#">{category}</Link>
          </div>
          <Link to={url} className="bl-item-title">
            <h2>{title}</h2>
          </Link>
          <div className="bl-item-meta">
            <span className="published">{formatDate(publishedAt)}</span>
            <span className="posted-by">
              - by{" "}
              <Link to="#" title="View all posts by Martin Vegas">
                {publishedBy}
              </Link>
            </span>
          </div>

          <div  style={{height:200}} className="hide-from-sm text-justify bl-item-desc">{summary}</div>
          <div   className="hide-to-sm text-center bl-item-desc">{summary}</div>

          {/*<Link className="margin-top-20" to={url} className="bl-item-read-more" title="Read More">*/}
            {/*<span />*/}
          {/*</Link>*/}
          <ul className="bl-item-attr">
            <AdminTools
              isPublished={isPublished}
              onPressRemove={onPressRemove}
              isAuth={isAuth}
              url={editUrl}
            />
          </ul>
        </div>
      </article>
    </div>
  );
};

Component.propTypes = {
  url: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  onPressRemove: PropTypes.func,
  body: PropTypes.string,
  publishedBy: PropTypes.string,
  publishedAt: PropTypes.string,
};
Component.defaultProps = {};

export default withLifeCycle({
  onDidMount({
               url,
               source,
               title,
               category,
               onItemReady,
             }: any) {
    const item = { url, title, source, category };
    onItemReady && onItemReady(item);
  }
})(Component);
