import $ from 'jquery';
export const init=()=>{
  $('.tt-dropdown-wrap').on("mouseenter",function(){
    $(this).addClass('tt-dropdown-open');
  }).on("mouseleave",function(){
    $(this).removeClass('tt-dropdown-open');
  });

// tt clobal search toggle
  $('.tt-clobal-search-trigger').on('click', function() {
    $('.tt-clobal-search').fadeIn(300, function() {
      $(this).addClass('search-open');
    });

    return false;
  });
  $('.tt-clobal-search-close').on('click', function() {
    $('.tt-clobal-search').fadeOut(300, function() {
      $(this).removeClass('search-open');
    });
  });
}

