import React,{Fragment} from "react";
import PropTypes from "prop-types";

const Component = ({views,comments}) => {
  return (
    <Fragment>
      <li>
        <a href="#blog-post-comments" className="blog-single-comment-count sm-scroll"
           title="Read the comments"><i className="far fa-comment"/> {comments}</a>
      </li>
      <li>
        <div className="blog-single-views-count" title="Post Views">
          <i className="fas fa-eye"/> {views}
        </div>
      </li>
    </Fragment>
  );
};

Component.propTypes = {
  comments:PropTypes.number,
  views:PropTypes.number,
};
Component.defaultProps = {
  comments:0,
  views:0
};

export default Component;
