import store from "../../../stores";
import { PostDataProvider } from "../../../providers/air-table";
const BASE_URL = "https://images.weserv.nl/";

export const preparePostImage = (
  _url: ?string,
  _h: number = 305,
  _w: number = 460,
  t: string = "&t=square",
  dpr: string = "&dpr=2",
  sharp: string = "&sharp",
  a: string = "&a=top"
) => {
  if (_url) {
    const w = _w ? `&w=${_w}` : "";
    const h = _h ? `&h=${_h}` : "";
    const url = _url.split("https://").pop();
    return `${BASE_URL}?url=ssl:${url}${w}${h}${t}${a}${sharp}${dpr}`;
  }
  return "";
};

export const removePostItem = (id: string, title?: string) => {
  store.uIStore.confirm(`Are you sure you want to remove ${title}`, () => {
    if (id) {
      PostDataProvider.remove(id).then(result => {
        console.log(result);
      });
    }
  });
};
