//flow
import React, { useState, useEffect } from "react";
import {isFunction} from 'lodash'
import { DetailItem2 } from "../../../../grid";

import DataProvider from "../../../../../providers/air-table";
import { preparePostImage,removePostItem } from "../../../common/util";
import { userHasRole } from "../../../../../util";

const baseId = "appTrzhZ9pouPZVad";
const apiKey = "keyIlEVmx0iN6PYZW";

const provider = new DataProvider({ apiKey, baseId });

const removeFirstImageTagFromBody = (bodyText: string) => {
  return bodyText.replace(/<img.*?"(.*?)"[^\>]+>/, "");
};

const wrapImagesWithFigureTag = (HtmlText: string) => {
  return removeFirstImageTagFromBody(HtmlText).replace(
    /<img.*?"(.*?)"[^\>]+>/g,
    "<figure class='single-post-image'>$&</figure>"
  );
};

type Type = {
  //provider:IAirTableApi,
  dataId: string,
  table: string,
  adminUrl: string,
  isAuth: any,
};

const Component = ({
  dataId,
  table,
  adminUrl,
  isAuth,
}: Type) => {
  const [isLoaded, setLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (!isLoaded)
      provider &&
        provider.getData(table, dataId).then(({ fields }: any) => {
          if(userHasRole('admin')||fields.isPublished){
            const body = wrapImagesWithFigureTag(fields.body);
            const src = preparePostImage(fields.summaryImgSrc, 405, 660);
            setData({ ...fields, body, src });
          }
          setLoaded(true);
        });
  });

  const onPressRemove=()=>{
    removePostItem(dataId,data.title)
  }

  return (
    <DetailItem2
      {...data}
      isAuth={isFunction(isAuth)?isAuth(data):isAuth}
      editUrl={adminUrl}
      onPressRemove={onPressRemove}
    />
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
