// @flow
import { parse } from "node-html-parser";

export const prepareTitle = (title: string, callback?: Function) =>
  callback && callback(title);
export const prepareBody = (body: any, callback?: Function) => {
  callback && callback(body);
};
export const prepareTag = (tags: Array<{ label: string, value: string }>) =>
  tags.reduce(
    (acc, tag) => {
      acc.tags.push(tag.value);
      acc._tags = acc._tags ? acc._tags + "," + tag.label : tag.label;
      return acc;
    },
    { tags: [], _tags: "" }
  );

export const prepareCategory = ({
  label,
  value
}: {
  label: string,
  value: string
}) => ({
  category: [value],
  _category: label
});

export const getSummaryUrl = (HtmlText:string) => {
  const doc = new DOMParser().parseFromString(HtmlText, "text/xml")
  const imgs = doc.getElementsByTagName("img");
  return imgs && imgs[0] && imgs[0].attributes.src.value;
};



export const selectConverter = (items: Array<string>): Array<Object> =>
  items.map(item => ({ value: item }));
