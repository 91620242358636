//flow
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "gatsby";

type Type = {
  actions: Array<any>
};

const Component = ({ actions }: Type) => {
  return (
    Array.isArray(actions) &&
    actions.map(
      action =>
        action &&
        (action.isAnchorTag ? (
          <a
            key={action.name}
            target={"_blank"}
            className={classNames(
              "btn",
              " margin-top-20",
              "margin-right-10",
              {
                "btn-primary": action.isPrimary && !action.btnStyle
              },
              {
                "btn-dark": !action.isPrimary && !action.btnStyle
              },
              action.btnStyle
            )}
            href={action.url || "#"}
          >
            {action.icon && <i className={action.icon} />}
            {action.name}
          </a>
        ) : (
          <Link
            key={action.name}
            className={classNames(
              "btn",
              " margin-top-20",
              "margin-right-10",
              {
                "btn-primary": action.isPrimary && !action.btnStyle
              },
              {
                "btn-dark": !action.isPrimary && !action.btnStyle
              },
              action.btnStyle
            )}
            to={action.url || "#"}
          >
            {action.icon && <i className={action.icon} />}
            {action.name}
          </Link>
        ))
    )
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
