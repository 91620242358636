// @flow
import React from "react";
import PropTypes from "prop-types";
import {
  containerClasses,
  imageClasses,
  colSrcClasses,
  contentClassesPC,
  contentClassesMobile,
  headerClasses
} from "./helpers";
import ContentActions from "./components/content-actions";
import ContentHeading from "./components/content-heading";
import {getBodyContent} from '../common/util'


const Component = ({
  auxSrc,
  src,
  wrap,
  imagePadding,
  isFullHeight,
  headingSize,
  headingPos,
  paddingOn,
  contentPos,
  body,
  title,
  subtitle,
  showBgrdImage,
  actions,
  isLeft,
  isRight,
  name,
  isContained,
  useAux,
  bodyClasses
}: any) => {
  return (
    <section id="about-me-section">
      <div id={name} className={containerClasses(wrap)}>
        <div className="split-box ">
          <div className="container-fluid">
            <div className="row">
              <div className="row-lg-height">
                {isLeft && !isRight && src && (
                  <div
                    className="col-lg-4 col-lg-height split-box-image no-padding bg-image background-fifty-percent"
                    style={{
                      backgroundImage: `url(${useAux ? auxSrc : src})`,
                      backgroundSize: isContained ? "contain" : "cover"
                    }}
                  >
                    <div className={imageClasses(imagePadding, isFullHeight)} />
                  </div>
                )}
                {!isLeft && isRight && src && (<div
                  className="hide-to-sm  col-lg-4 col-lg-height split-box-image no-padding bg-image background-fifty-percent"
                  style={{
                    backgroundImage: `url(${useAux ? auxSrc : src})`,
                    backgroundSize: isContained ? "contain" : "cover"
                  }}
                >
                  <div className={imageClasses(imagePadding, isFullHeight)} />
                </div>
                )}
                <div className={colSrcClasses(src)}>
                  {showBgrdImage && (
                    <div
                      className="full-cover for-light-style bg-gray-3 bg-image background-fifty-percent"
                      style={{
                        backgroundImage:
                          "url(img/pattern/bg-pattern-1-light.png)"
                      }}
                    />
                  )}
                  {showBgrdImage && (
                    <div
                      className="full-cover for-dark-style bg-gray-3 bg-image background-fifty-percent"
                      style={{
                        backgroundImage:
                          "url(img/pattern/bg-pattern-1-dark.png)"
                      }}
                    />
                  )}
                  <div className={contentClassesMobile(contentPos, isRight)}>
                    <ContentHeading
                      paddingOn={paddingOn}
                      headingSize={headingSize}
                      headingPos={headingPos}
                      subtitle={subtitle}
                      title={title}
                    />
                    {getBodyContent(body, bodyClasses)}
                    <ContentActions  actions={actions} />
                  </div>
                  <div className={contentClassesPC(contentPos, isRight)}>
                    <ContentHeading
                      paddingOn={paddingOn}
                      headingSize={headingSize}
                      headingPos={headingPos}
                      subtitle={subtitle}
                      title={title}
                    />
                    {getBodyContent(body, bodyClasses)}
                    <ContentActions  actions={actions} />
                  </div>
                </div>
                {isRight && !isLeft && src && (
                  <div
                    className="hide-from-sm col-lg-4 col-lg-height split-box-image no-padding bg-image background-fifty-percent"
                    style={{
                      backgroundImage: `url(${useAux ? auxSrc : src})`,
                      backgroundSize: isContained ? "contain" : "cover"
                    }}
                  >
                    <div className={imageClasses(imagePadding, isFullHeight)} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Component.propTypes = {
  src: PropTypes.string,
  auxSrc: PropTypes.string,
  wrap: PropTypes.string,
  imagePadding: PropTypes.number,
  isFullHeight: PropTypes.bool,
  headingSize: PropTypes.string,
  headingPos: PropTypes.string,
  paddingOn: PropTypes.bool,
  contentPos: PropTypes.string,
  body: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showBgrdImage: PropTypes.bool,
  actions: PropTypes.array,
  isRight: PropTypes.bool,
  isLeft: PropTypes.bool,
  isContained: PropTypes.bool,
  useAux: PropTypes.bool
};

Component.defaultProps = {
  imagePadding: 40
};

export default Component;
