// @flow
import type { IHit } from "../../../../../../definitions";
import { prepareImage } from "../../../../../../util";
const TRANSFORMER =
  "f_auto,q_auto,b_white,c_pad,h_450,e_sharpen/";

export const converter = (hit: IHit) => {
  return {
    id: hit.id,
    imageId: hit.image.src,
    title: hit.title,
    url: hit.url,
    // url: `/gallery/${hit.accessionNumber}`,
    subtitle: hit.artist,
    src: prepareImage(hit.image.src, TRANSFORMER),
    hideImage: hit.hideImage,
    showDetail: hit.showDetail
  };
};
