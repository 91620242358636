// @flow
import React from "react";
import { Item, Search } from "./components";
import { compose, withHandlers } from "recompose";
import { connectRefinementList } from "react-instantsearch-dom";
import PropTypes from "prop-types";

const Component = ({ items, isFromSearch, onRefine,currentRefinement, searchForItems }:any) => (
  <div>
    <Search onChange={searchForItems} />
    <div className="refinementList">
      {items &&
        items.map(item => (
        <Item
        key={item.label}
        isFromSearch
        item={item}
        currentRefinement={currentRefinement}
        onChange={onRefine}
        {...item}
        />
        ))
      }

    </div>
  </div>
);

Component.propTypes = {
  attribute: PropTypes.string,
  defaultRefinement: PropTypes.any,
  operator: PropTypes.any,
  limit: PropTypes.any,
  showMore: PropTypes.bool,
  showMoreLimit: PropTypes.any,
  searchable: PropTypes.bool,
  transformItems: PropTypes.any,
  translations: PropTypes.any,
  onRefine: PropTypes.func
};

export default compose(
  connectRefinementList,
  withHandlers({
    onRefine:({refine,onRefine})=>(value)=>{
      refine(value)
      onRefine && onRefine()
    }
  })
)(Component);
