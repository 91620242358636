import React from "react";
import {connectCurrentRefinements} from 'react-instantsearch-dom'
import PropTypes from "prop-types";

const Component = ({items,refine}) => {
  return (items.length ? <div className="ais-ClearRefinements">
      <button className="ais-ClearRefinements-button" onClick={() => refine(items)}>
        Clear all refinements
      </button>
    </div>:null)
};

Component.propTypes = {};
Component.defaultProps = {};

export default connectCurrentRefinements(Component);
