// @flow
import React from "react";
import PropTypes from "prop-types";

const Component = ({title,category}:any) => {
  return (
    <div className="blog-single-post-headin">
      <h1 className="blog-single-post-title">{title}</h1>
      <div className="blog-single-post-category"><a href="#">{category}</a></div>
    </div>
  );
};

Component.propTypes = {
  title:PropTypes.string,
  category:PropTypes.string,
};
Component.defaultProps = {};

export default Component;
