// @flow
import { observable, computed, toJS } from "mobx";
import type { IArtlistItem, IuIStore } from "../definitions";
import { random } from "lodash";
import netlifyIdentity from "netlify-identity-widget";
import { getPageStats } from "../api";

const isBrowser = () => typeof window !== "undefined";

const NAV_KEY = "navItems";
const VISITED_KEY = "visitedItems";

const storeMap = (key, items: Map<any, any>) => {
  if (isBrowser()) {
    const payload = JSON.stringify(Array.from(items));
    window.sessionStorage.setItem(key, payload);
  }
};

const getMap = (key: string): Map<any, any> => {
  if (isBrowser() && window.sessionStorage.getItem(key)) {
    const res = sessionStorage.getItem(key);
    const payload = res && JSON.parse(res);
    if (payload) {
      return new Map(payload);
    }
  }
  return new Map();
};

export class UIStore implements IuIStore {
  constructor() {
    this.authListeners();
    this._visitedItems = getMap(VISITED_KEY);
    this._itemNavList = getMap(NAV_KEY);
    this.collectionPath = "/collection";
  }

  getPage(filters: string) {
    const pages = this._getTotalPages(filters);
    return random(pages) || 1;
  }

  _getTotalPages(filters: string) {
    if (isBrowser()) {
      if (window.localStorage.getItem(filters)) {
        const result = window.localStorage.getItem(filters);
        return result && JSON.parse(result).nbPages;
      } else {
        getPageStats(filters).then(({ nbPages }) => {
          window.localStorage.setItem(
            filters,
            JSON.stringify({ nbPages, expires: 15 })
          );
        });
      }
      return 0;
    }
  }

  confirm(message: string, ok?: Function, cancel?: Function) {
    if (window.confirm(message)) {
      ok && ok();
    } else {
      cancel && cancel();
    }
  }

  @observable _visitedItems: Map<string, IArtlistItem>;
  _itemNavList: Map<string, number>;

  @computed get visitedItems(): Array<IArtlistItem> {
    return Array.from(this._visitedItems.values());
  }
  addToNav(currNav: string): void {
    const pos = this._itemNavList.get(currNav) || this._itemNavList.size;
    this._itemNavList.set(currNav, pos);
    storeMap(NAV_KEY, toJS(this._itemNavList));
  }

  clearNav():void {
    this._itemNavList.clear()
  }

  getNextNav(currNav: string): ?string {
    const currPos = this._itemNavList.get(currNav) || 0;
    const pos = this.hideNext(currNav) ? currPos : currPos + 1;
    return Array.from(this._itemNavList.keys())[pos];
  }

  hideNext(currNav: string) {
    const currPos = this._itemNavList.get(currNav) || 0;
    return (
      this._itemNavList.size === 0 || currPos + 1 === this._itemNavList.size
    );
  }

  hidePrev(currNav: string) {
    const currPos = this._itemNavList.get(currNav) || 0;
    return currPos - 1 < 0;
  }

  getPrevNav(currNav: string): ?string {
    const currPos = this._itemNavList.get(currNav) || 0;
    const pos = this.hidePrev(currNav) ? 0 : currPos - 1;
    return Array.from(this._itemNavList.keys())[pos];
  }

  collectionPath: string;

  @observable isAuthenticated: boolean;
  @observable user: ?any;

  authenticate(mode: string = "login") {
    netlifyIdentity.open(mode);
  }

  authListeners(callback?: Function) {
    netlifyIdentity.on("logout", () => {
      this.isAuthenticated = false;
      this.user = null;
      callback && callback();
    });

    netlifyIdentity.on("login", user => {
      this.isAuthenticated = true;
      this.user = user;
      callback && callback(user);
    });

    netlifyIdentity.on("init", user => {
      if (user) {
        this.isAuthenticated = true;
      }
      this.user = user;
      callback && callback(user);
    });
  }

  signout() {
    netlifyIdentity.logout();
  }

  addVisitedItem(payload: IArtlistItem): void {
    if (payload) {
      const key = payload.url;
      if (!this._visitedItems.has(key)) {
        this._visitedItems.set(key, payload);
      }
      storeMap(VISITED_KEY, toJS(this._visitedItems));
    }
  }
}

export default { uIStore: new UIStore() };
