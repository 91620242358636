// @flow
import React from "react";
import CarouselItem from "./components/carousel-item";
import {
  getCarouselClasses,
  carouselDefaults
} from "./helpers";
import withLifeCycle from "@hocs/with-lifecycle";
import { compose, defaultProps } from "recompose";
import { init } from "./js";
import PropTypes from "prop-types";

const Component = ({
  carouselItems,
  navOutside,
  navOutsideTop,
  navBottomRight,
  rounded,
  navLight,
  dotsOutside,
  dotsPosition,
  mouseWheel,
  cursorGrab,
  hoverEffect,
  height,
  mobileFullHeight,
  items,
  itemsMobilePortrait,
  itemsMobileLandscape,
  itemsTabletPortrait,
  itemsTabletLandscape,
  loop,
  centered,
  margin,
  startAt,
  animateIn,
  animateOut,
  mouseDrag,
  touchDrag,
  autoHeight,
  autoPlay,
  timeout,
  autoPlaySpeed,
  pauseOnHover,
  dragEndSleep,
  nav,
  navSpeed,
  dots,
  dotsSpeed,
  children
}:any) => {
  return (
    <section id="tt-intro" className="slideshow-intro">
      <div className="tt-intro-inner">
        <div className="gl-carousel-wrap no-padding">
          <div
            className={getCarouselClasses({
              navOutside,
              navOutsideTop,
              navBottomRight,
              rounded,
              navLight,
              dotsOutside,
              dotsPosition,
              mouseWheel,
              cursorGrab,
              hoverEffect,
              height,
              mobileFullHeight
            })}
            data-items={items}
            data-mobile-landscape={itemsMobileLandscape}
            data-mobile-portrait={itemsMobilePortrait}
            data-tablet-landscape={itemsTabletLandscape}
            data-tablet-portrait={itemsTabletPortrait}
            data-margin={margin}
            data-center={centered}
            data-loop={loop}
            data-nav={nav}
            data-nav-speed={navSpeed}
            data-dots={dots}
            data-dots-speed={dotsSpeed}
            data-autoplay={autoPlay}
            data-autoplay-timeout={timeout}
            data-autoplay-speed={autoPlaySpeed}
            data-autoplay-hover-pause={pauseOnHover}
            data-autoheight={autoHeight}
            data-start-position={startAt}
            data-animate-in={animateIn}
            data-animate-out={animateOut}
            data-mouse-drag={mouseDrag}
            data-touch-drag={touchDrag}
            data-drag-end-speed={dragEndSleep}
          >
            {children?children : carouselItems && carouselItems.map((carouselItem, index) => (
              <CarouselItem key={index} {...carouselItem} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Component.propTypes = {
  carouselItems:PropTypes.array,
  navOutside:PropTypes.bool,
  navOutsideTop:PropTypes.bool,
  navBottomRight:PropTypes.bool,
  rounded:PropTypes.bool,
  navLight:PropTypes.bool,
  dotsOutside:PropTypes.bool,
  dotsPosition:PropTypes.string,
  mouseWheel:PropTypes.bool,
  cursorGrab:PropTypes.bool,
  hoverEffect:PropTypes.string,
  height:PropTypes.number,
  mobileFullHeight:PropTypes.bool,
  items:PropTypes.number,
  itemsMobilePortrait:PropTypes.number,
  itemsMobileLandscape:PropTypes.number,
  itemsTabletPortrait:PropTypes.number,
  itemsTabletLandscape:PropTypes.number,
  loop:PropTypes.bool,
  centered:PropTypes.bool,
  margin:PropTypes.number,
  startAt:PropTypes.number,
  animateIn:PropTypes.string,
  animateOut:PropTypes.string,
  mouseDrag:PropTypes.bool,
  touchDrag:PropTypes.bool,
  autoHeight:PropTypes.bool,
  autoPlay:PropTypes.bool,
  timeout:PropTypes.number,
  autoPlaySpeed:PropTypes.number,
  pauseOnHover:PropTypes.bool,
  dragEndSleep:PropTypes.any,
  nav:PropTypes.bool,
  navSpeed:PropTypes.number,
  dots:PropTypes.bool,
  dotsSpeed:PropTypes.number
};

export default compose(
  defaultProps(carouselDefaults),
  withLifeCycle({
    onDidMount() {
      init();
    }
  })
)(Component);
