import React,{useState} from "react";
import {Link,navigateTo} from 'gatsby'
import qs from 'qs'
import PropTypes from "prop-types";

const prepareQuery = (query:string) =>
  `?${qs.stringify({ page: 1, query})}`;

const Component = ({onSearch}) => {
  const [search,setSearch]=useState('')


  const onChange = (e)=>{
    setSearch(e.target.value)
  }

  const onSubmit=(e)=>{
    e.preventDefault();
    const url = `/collection${prepareQuery(search)}`;
    navigateTo(url);
    setSearch('');
  }

  return (
    <li>
      <Link to="#" className="tt-clobal-search-trigger">
        <i className="fas fa-search" />
      </Link>
      <div className="tt-clobal-search">
        <div className="tt-clobal-search-inner">
          <span className="tt-clobal-search-title">Search</span>
          <form
            id="tt-clobal-search-form"
            className="form-btn-inside"
            method="get"
            onSubmit={onSubmit}
          >
            <input
              type="text"
              id="tt-clobal-search-input"
              name="search"
              value={search}
              onChange={onChange}
              placeholder="Keywords for any artwork ..."
            />
            <button type="submit">
              <i className="fas fa-search" />
            </button>
          </form>
        </div>

        <div className="tt-clobal-search-close">
          <i className="tt-close-btn tt-close-light" />
        </div>
      </div>
    </li>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
