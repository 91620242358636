//flow
import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "lodash";
import Slider from "../../../../../../slider";
import { normalizeImageSrc } from "../../../../../../../util";
import Image from "../../../../../../cloudinary-image";
import { loadImageDisplay, prepareContent } from "../../helpers";

const CROP = "pad";
const IMAGE_BGRD = "auto";

type Type = {
  thumbs: Array<any>,
  artist: any,
  title: string,
  height: number
};

const Component = ({ thumbs, artist, height, title, ...rest }: Type) => (
  <Slider {...rest}>
    {thumbs.map(thumb => (
      <li key={camelCase(thumb.src)} data-thumb={normalizeImageSrc(thumb.src)}>
        <Image
          id={camelCase(normalizeImageSrc(thumb.src))}
          onClick={() =>
            loadImageDisplay(thumbs, prepareContent(title, artist))
          }
          crop={CROP}
          background={IMAGE_BGRD}
          height={height}
          publicId={normalizeImageSrc(thumb.src)}
        />
      </li>
    ))}
  </Slider>
);

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
