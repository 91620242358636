// @flow
import React, { useEffect, useState } from "react";
import $ from "jquery";
import Trumbowyg from "./components/trumbowg";
import IconsPath from "trumbowyg/dist/ui/icons.svg";
import { debounce } from "lodash";
import Toggle from "react-toggle";
import { omit } from "lodash/fp";
import Category from "./components/category";
import Tags from "./components/tags";
import PropTypes from "prop-types";
import {
  prepareBody,
  prepareCategory,
  prepareTag,
  getSummaryUrl
} from "./helpers";
import ellipsize from "ellipsize";
import type { IAirTableApi } from "../../../../../definitions";
import { PostDataProvider } from "../../../../../providers/air-table";
import { FunctionTypeAnnotation } from "babel-types";

const ID = "myContentEditable";
const prepareEditorFields = omit([
  "createdAt",
  "url",
  "lastPublishedAt",
  "user",
  "publishedBy"
]);

const WAIT = 400;
const SOURCE_TABLE = "Posts";
const SUMMARY_SIZE = 200;
const PLUGINS = {
  upload: {
    serverPath: "https://api.imgur.com/3/image",
    fileFieldName: "image",
    headers: { Authorization: "Client-ID d563e56c2678496" },
    urlPropertyName: "data.link",
    imageWidthModalEdit: true
  }
};
const DEFAULT_VALUES = {
  title: "",
  category: [],
  tags: [],
  body: "",
  isPublished: false
};

const debouncedBody = debounce(prepareBody, WAIT);

const Component = ({
  provider,
  dataId,
  publishedBy,
  user,
  hideCategory,
  hideTags,
  hidePublish,
  afterSave
}: {
  provider: IAirTableApi,
  dataId: any,
  publishedBy?: string,
  user?: string,
  hideCategory?: boolean,
  hideTags?: boolean,
  hidePublish?: boolean,
  afterSave?: Function
}) => {
  const [data, setData] = useState(DEFAULT_VALUES);
  const [body, setBody] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [editId] = useState(dataId === "new" ? null : dataId);

  const [select, setSelect] = useState({
    category: undefined,
    tags: undefined
  });
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (editId && !isLoaded && !isBusy) {
      provider
        .getData(SOURCE_TABLE, editId)
        .then(result => {
          if (result && result.fields) {
            setData(state => ({
              ...state,
              ...prepareEditorFields(result.fields)
            }));
            setBody(result.fields.body);
            setIsBusy(false);
            setLoaded(true);
          }
        })
        .catch(err => {
          console.log(err);
          setIsBusy(false);
          setLoaded(true);
        });
    } else {
      setLoaded(true);
    }
  });

  const clear = () => {
    setData(DEFAULT_VALUES);
    setSelect(() => ({ category: null, tags: null }));
    $(`#${ID}`).trumbowyg("empty");
  };

  const onSave = async () => {
    try {
      if (data.title && data.body) {
        const payload = { ...data, publishedBy, user };
        setIsBusy(true);
        let results;
        if (editId) {
          results = await provider.update(editId, payload);
        } else {
          results = await provider.create(payload);
        }
        clear();
        setIsBusy(false);
        afterSave && afterSave(results)
      }
    } catch (err) {
      //console.log(err);
      setIsBusy(false);
      clear();
    }
  };

  const onTitleChange = (event: any) => {
    const title = event.target.value;
    setData(state => ({ ...state, title }));
  };

  const onPublish = e => {
    const isPublished = e.target.checked;
    setData(state => ({ ...state, isPublished }));
  };

  const onCatChange = (payload: any) => {
    const { category, _category } = prepareCategory(payload);
    setData(state => ({ ...state, category, _category }));
    setSelect(() => ({ category: payload }));
  };

  const onTagChange = (payload: any) => {
    const { tags, _tags } = prepareTag(payload);
    setData(state => ({ ...state, tags, _tags }));
    setSelect(() => ({ tags: payload }));
  };

  const onChange = (payload: any) => {
    debouncedBody(payload.target, item => {
      const body = $(`#${ID}`).trumbowyg("html");
      const summaryImgSrc = getSummaryUrl(body);
      const summary = ellipsize(item.textContent, SUMMARY_SIZE);
      setData(state => ({ ...state, body, summary, summaryImgSrc }));
    });
  };

  return (
    isLoaded && (
      <div>
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              onChange={onTitleChange}
              className="form-control"
              name="title"
              value={data.title}
              placeholder="Title"
              required
            />
          </div>
        </div>

        {!hidePublish && (
          <div className="col-lg-12">
            <div className="form-group">
              <div>Publish Post?</div>
              <Toggle checked={data.isPublished} onChange={onPublish} />
            </div>
          </div>
        )}

        {!hideCategory && (
          <div style={{ zIndex: 17 }} className="position-relative col-lg-12">
            <Category
              defaults={data.category}
              value={select.category}
              provider={provider}
              isDisabled={isBusy}
              onChange={onCatChange}
            />
          </div>
        )}

        {!hideTags && (
          <div style={{ zIndex: 16 }} className="position-relative col-lg-12">
            <Tags
              defaults={data.tags}
              value={select.tags}
              onChange={onTagChange}
              provider={provider}
              isBusy={isBusy}
            />
          </div>
        )}

        <div className="col-lg-12">
          <Trumbowyg
            onChange={onChange}
            disabled={isBusy}
            shouldInjectSvgIcons={false}
            svgIconsPath={IconsPath}
            id={ID}
            removeformatPasted
            autogrowOnEnter={false}
            placeholder={"Enter your content here"}
            btnsDef={{
              image: {
                dropdown: ["insertImage", "upload"],
                ico: "insertImage"
              }
            }}
            buttons={[
              ["viewHTML"],
              ["undo", "redo"],
              "btnGrp-semantic",
              ["strong", "em", "del"],
              ["superscript", "subscript"],
              ["link"],
              ["image"],
              "btnGrp-justify",
              "btnGrp-lists",
              ["horizontalRule"],
              ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
              ["unorderedList", "orderedList"],
              ["removeformat"],
              ["fullscreen"]
            ]}
            plugins={PLUGINS}
            data={body}
          />
        </div>

        <div className="col-lg-12 text-right">
          <a onClick={onSave} className="btn  btn-primary">
            {isBusy ? "...Saving" : "Save"}
          </a>
        </div>
      </div>
    )
  );
};

Component.propTypes = {
  provider: PropTypes.any,
  isBusy: PropTypes.bool,
  dataId: PropTypes.string,
  hideCategory: PropTypes.bool,
  hideTags: PropTypes.bool,
  hidePublish: PropTypes.bool,
  afterSave: PropTypes.func
};

Component.defaultProps = {
  provider: PostDataProvider
};

export default Component;
