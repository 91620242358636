import $ from 'jquery'
export const init = () => {
  $(".owl-carousel").each(function() {
    var $carousel = $(this);
    $carousel.owlCarousel({
      items: $carousel.data("items"),
      loop: $carousel.data("loop"),
      margin: $carousel.data("margin"),
      center: $carousel.data("center"),
      startPosition: $carousel.data("start-position"),
      animateIn: $carousel.data("animate-in"),
      animateOut: $carousel.data("animate-out"),
      autoWidth: $carousel.data("autowidth"),
      autoHeight: $carousel.data("autoheight"),
      autoplay: $carousel.data("autoplay"),
      autoplayTimeout: $carousel.data("autoplay-timeout"),
      autoplayHoverPause: $carousel.data("autoplay-hover-pause"),
      autoplaySpeed: $carousel.data("autoplay-speed"),
      nav: $carousel.data("nav"),
      navText: ["", ""],
      navSpeed: $carousel.data("nav-speed"),
      dots: $carousel.data("dots"),
      dotsSpeed: $carousel.data("dots-speed"),
      mouseDrag: $carousel.data("mouse-drag"),
      touchDrag: $carousel.data("touch-drag"),
      dragEndSpeed: $carousel.data("drag-end-speed"),
      lazyLoad: $carousel.data("lazy-load"),
      video: true,
      responsive: {
        0: {
          items: $carousel.data("mobile-portrait"),
          center: false
        },
        480: {
          items: $carousel.data("mobile-landscape"),
          center: false
        },
        768: {
          items: $carousel.data("tablet-portrait"),
          center: false
        },
        992: {
          items: $carousel.data("tablet-landscape")
        },
        1200: {
          items: $carousel.data("items")
        }
      }
    });
  });

  // Mousewheel plugin
  var owlMouse = $(".owl-mousewheel");
  owlMouse.on("mousewheel", ".owl-stage", function(e) {
    if (e.deltaY > 0) {
      owlMouse.trigger("prev.owl", [800]);
    } else {
      owlMouse.trigger("next.owl", [800]);
    }
    e.preventDefault();
  });

  // Keyboard (prev/next arrow) events for navigating
  // https://github.com/OwlCarousel2/OwlCarousel2/issues/492#issuecomment-55629470
  var owlKeyboard = $(".owl-carousel");
  $(document).keyup(function(i) {
    if (i.keyCode == 37) {
      owlKeyboard.trigger("prev.owl", [800]);
    } else if (i.keyCode == 39) {
      owlKeyboard.trigger("next.owl", [800]);
    }
  });

  // CC item hover
  $(".cc-item").on("mouseenter", function() {
    $(".owl-carousel").addClass("cc-item-hovered");
  });
  $(".cc-item").on("mouseleave", function() {
    $(".owl-carousel").removeClass("cc-item-hovered");
  });

  // If ".cc-caption" exist add class "cc-caption-on" to ".cc-item".
  $(".cc-item").each(function() {
    if ($(this).find(".cc-caption").length) {
      $(this).addClass("cc-caption-on");
    }
  });
};
