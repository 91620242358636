// @flow
import React from "react";
import PropTypes from "prop-types";
import germanFlag from "./flags/de.png";
import frenchFlag from "./flags/fr.png";
import englishFlag from "./flags/gb.png";
import classNames from "classnames";

const Component = ({ isDark }:any) => {
  return (
    <li
      className={classNames(
        "tt-dropdown-wrap",
        "tt-dropdown-master",
        "tt-dropdown-right",
        "tt-tools-lang",
        { "tt-dropdown-dark": isDark }
      )}
    >
      <a href="#">
        <img src={englishFlag} alt="English" /> EN
      </a>
      <ul className="tt-dropdown">
        <li>
          <a href="" title="English">
            <img src={englishFlag} alt="English" /> EN
          </a>
        </li>
        <li>
          <a href="" title="French">
            <img src={frenchFlag} alt="French" /> FR
          </a>
        </li>
        <li>
          <a href="" title="Deutsch">
            <img src={germanFlag} alt="Deutsch" /> DE
          </a>
        </li>
      </ul>
    </li>
  );
};

Component.propTypes = {
  isDark: PropTypes.bool
};
Component.defaultProps = {};

export default Component;
