// @flow
import classNames from "classnames";

type Type = {
  navOutside: boolean,
  navOutsideTop: boolean,
  navBottomRight: boolean,
  rounded: boolean,
  navLight: boolean,
  dotsOutside: boolean,
  dotsPosition: string,
  mouseWheel: boolean,
  cursorGrab: boolean,
  hoverEffect: string,
  height: number,
  mobileFullHeight: boolean
};
export const getCarouselClasses = ({
  navOutside,
  navOutsideTop,
  navBottomRight,
  rounded,
  navLight,
  dotsOutside,
  dotsPosition,
  mouseWheel,
  cursorGrab,
  hoverEffect,
  height,
  mobileFullHeight
}: Type) => {
  const _height = isNaN(height) || height > 6 ? "full" : height;
  return classNames(
    "owl-carousel",
    { "nav-outside": navOutside },
    { "nav-outside-top": navOutsideTop },
    { "nav-bottom-right": navBottomRight },
    { "nav-rounded": rounded },
    { "dots-rounded": rounded },
    { "nav-light": navLight },
    { "dots-outside": dotsOutside },
    { "owl-mousewheel": mouseWheel },
    { "cursor-grab": cursorGrab },
    { "cc-height-m": mobileFullHeight },
    `dots-${dotsPosition}`,
    `cc-hover-${hoverEffect}`,
    `cc-height-${_height}`
  );
};

export const carouselDefaults = {
  navOutside: false,
  navOutsideTop: false,
  navBottomRight: false,
  rounded: false,
  navLight: false,
  dotsOutside: false,
  dotsPosition: "right",
  mouseWheel: true,
  cursorGrab: true,
  hoverEffect: "zoom",
  height: 5,
  mobileFullHeight: true,
  items: 1,
  itemsMobilePortrait: 1,
  itemsMobileLandscape: 1,
  itemsTabletPortrait: 1,
  itemsTabletLandscape: 1,
  loop: true,
  centered: true,
  margin: 0,
  startAt: 1,
  animateIn: "fadeIn",
  animateOut: "fadeOut",
  mouseDrag: false,
  touchDrag: false,
  autoHeight: false,
  autoPlay: true,
  timeout: 8000,
  autoPlaySpeed: 500,
  pauseOnHover: true,
  dragEndSleep: 800,
  nav: true,
  navSpeed: 500,
  dots: true,
  dotsSpeed: 500
};

export default () => {
  return null;
};
