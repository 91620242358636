// @flow
import React from "react";
import Item from "../../../../../grid/item/item-1";
import { connectHits } from "react-instantsearch-dom";
import { withHandlers, compose } from "recompose";
import { converter } from "./helpers";
import type { IHit } from "../../../../../../definitions";
import PropTypes from "prop-types";

const Component = ({
  hits,
  onItemReady,
  itemStyle,
  getTools
}: {
  hits: Array<IHit>,
  onItemReady: Function,
  itemStyle: number,
  getTools: Function
}) => {
  const _getTools = (hit: any) => () => getTools && getTools(hit);
  return hits.map((hit, key) => (
    <Item
      getTools={_getTools({...hit,...converter(hit)})}
      navigateWithClick
      itemStyle={itemStyle}
      onItemReady={onItemReady}
      key={hit.id}
      {...converter(hit)}
    />
  ));
};

Component.propTypes = {
  onItemReady: PropTypes.func,
  itemStyle: PropTypes.number,
  getTools: PropTypes.func
};
Component.defaultProps = {};
export default compose(
  connectHits,
  withHandlers(() => {
    let itemNumber = 0;
    return {
      onItemReady: ({ onItemReady }: { onItemReady: Function }) => item => {
        itemNumber = itemNumber + 1;
        onItemReady && onItemReady({ item, itemNumber });
      }
    };
  })
)(Component);
