import React, { Component } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { InstantSearch } from "react-instantsearch-dom";

import qs from "qs";

const DEBOUNCE_TIME = 400;
const INDEX = process.env.ALG_INDEX_NAME;
const ID = process.env.ALG_APP_ID;
const KEY = process.env.ALG_API_KEY;

const createURL = state => {
  return `?${qs.stringify(state)}`;
}

const searchStateToUrl = (location, searchState) =>
  searchState && location
    ? `${location.pathname}${createURL(searchState)}`
    : "";

const urlToSearchState = (props:any) => {
  const {location,onUrlChange}=props
  const state = location && qs.parse(location.search.slice(1)) || {};
  const path = searchStateToUrl(location,state)
  onUrlChange && onUrlChange(path)
  return state
};

class InstantSearchWrapper extends Component {
  state = {
    searchState: urlToSearchState(this.props),
    lastLocation: this.props.location
  };

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props),
        lastLocation: props.location
      };
    }

    return null;
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState);
    const path = searchStateToUrl(this.props.location, searchState);
    this.props.onUrlChange && this.props.onUrlChange(path);
    this.debouncedSetState = setTimeout(() => {
      navigate(path, searchState);
    }, DEBOUNCE_TIME);

    this.setState({ searchState });
  };

  render() {
    const { children, indexName, apiKey, appId } = this.props;
    return (
      <InstantSearch
        indexName={indexName}
        appId={ID}
        apiKey={KEY}
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange}
        createURL={createURL}
      >
        {children}
      </InstantSearch>
    );
  }
}

InstantSearchWrapper.defaultProps = {
  indexName: INDEX
};

InstantSearchWrapper.propTypes = {
  indexName: PropTypes.string
};

export default InstantSearchWrapper;
