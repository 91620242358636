// @flow
import React from "react";
import {Links,Tools} from './components'
import withLifeCycle from '@hocs/with-lifecycle';
import PropTypes from "prop-types";
import {init} from './js'

const Component = ({urlLinks,isDark,renderTools,showLanguageMenu}:any) => {
  return (
    <nav className="tt-main-menu">
      <div id="tt-m-menu-toggle-btn">
        <span/>
      </div>
      <Tools showLanguageMenu={showLanguageMenu} renderTools={renderTools} isDark={isDark}/>
      <Links  isDark={isDark} urlLinks={urlLinks}  />
    </nav>
);
};

Component.propTypes = {
  urlLinks:PropTypes.array,
  isDark:PropTypes.bool,
  showLanguageMenu:PropTypes.bool,
  renderTools:PropTypes.func,
};

export default withLifeCycle(
  {
    onDidMount() {
      // setTimeout(()=>{init()},4000)
      init()
    }
  }
)(Component);
