//flow
import Provider from "../providers/instant-search-2";
import { Configure } from "react-instantsearch-dom";
import React from "react";
import { pick } from "lodash/fp";
import { intersection } from "lodash";
import { userHasRole } from "../util";

const CONFIG_KEYS = [
  "filters",
  "offset",
  "length",
  "optionalFilters",
  "page",
  "hitsPerPage"
];

const canConfigure = (props: Object) =>
  intersection(Object.keys(props), CONFIG_KEYS).length > 0;

const prepareFilters = (primaryFilter: string, filters?: string) =>{
  const normalizedWord = (word:string)=>word.toLowerCase()
  if(filters){
    const CONJUNCT = normalizedWord(filters).startsWith('and')
      || normalizedWord(filters).startsWith('or') ? '' : ' AND ';
    return `${primaryFilter} ${CONJUNCT} ${filters}`
  }else{
    return primaryFilter
  }
};
 // filters ? `${primaryFilter} AND ${filters}` : primaryFilter;

const configureProps = pick(CONFIG_KEYS);

export default (propsFunc: Function | string) => WrappedComponent => props => {
  const compProps =
    typeof propsFunc === "function"
      ? propsFunc(props)
      : typeof propsFunc === "string"
      ? { ...props, indexName: propsFunc }
      : props;
  const { filters } = props;
  const configProps = !userHasRole('admin')
    ? {
        ...configureProps(compProps),
        ...{ filters: prepareFilters('isPublished:true', filters) }
      }
    : configureProps(compProps);
  return (
    <Provider {...compProps}>
      {canConfigure(configProps) && <Configure {...configProps} />}
      <WrappedComponent {...props} />
    </Provider>
  );
};
