// @flow
import $ from "jquery";
import React from "react";
import { compose,defaultProps } from "recompose";
import PropTypes from "prop-types";
import withLifeCycle from "@hocs/with-lifecycle";


const Component = ({children,id }: any) => {
  return (
    <div id="my-image">
      <ul id={id}>
        {children}
      </ul>
    </div>
  );
};

Component.propTypes = {
  isGallery: PropTypes.bool,
  id:PropTypes.string,
  items: PropTypes.number,
  thumbItems:PropTypes.number,
  slideMargin:PropTypes.number,
  enableDrag:PropTypes.bool,
  loop:PropTypes.bool,
  responsive:PropTypes.any,
};

export default compose(
  defaultProps({
    id:'lightSlider',
    thumbItems:0,
    isGallery:false,
    loop:false,
    items:1,
    slideMargin: 0,
    enableDrag:false,
    responsive:[
      {
        breakpoint:800,
        settings: {
          item:1,
          slideMargin:6,
        }
      },
      {
        breakpoint:480,
        settings: {
          item:1,
        }
      }
    ]
  }),
  withLifeCycle({
    onDidMount({thumbItems,isGallery,items,enableDrag,loop,slideMargin,id,responsive,wait}:any) {
      setTimeout(()=>{
        $(`#${id}`).lightSlider({
          gallery: isGallery,
          onSliderLoad:(el)=>{
            console.log(thumbItems)
          },
          item: items,
          loop: loop,
          slideMargin: slideMargin,
          thumbItem: thumbItems,
          enableDrag: enableDrag,
          responsive:responsive,

        });
      },wait)

    }
  })
)(Component);
