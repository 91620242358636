// @flow
import React from "react";
import PropTypes from "prop-types";

const Component = ({ favCount, isActive }: any) => {
  return (
    <div className="favorite-btn">
      <div className="fav-inner">
        <div className="icon-heart">
          {isActive ? (
            <span className="icon-heart-filled" />
          ) : (
            <span className="icon-heart-empty" />
          )}
        </div>
      </div>
      <div className="fav-count">{favCount}</div>
    </div>
  );
};

Component.propTypes = {
  favCount: PropTypes.number,
  isActive: PropTypes.bool
};
Component.defaultProps = {
  favCount: 0,
  isActive: false
};

export default Component;
