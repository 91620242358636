import classnames from "classnames";
export const containerClasses = wrap =>
  classnames("about-me-inner", { "tt-wrap": wrap });
export const imageClasses = (imagePadding, isFullHeight) =>
  classnames("sbi-height", `padding-height-${imagePadding}`, {
    "full-height-vh": isFullHeight
  });
export const headerClasses = (headingSize, headingPos,paddingOn) =>
  classnames(`tt-heading tt-heading-${headingSize} text-${headingPos}`,{'padding-on':paddingOn});

export const colSrcClasses = src => {
  return classnames(
    "col-lg-height col-lg-middle no-padding",
    { "col-lg-6 ": !src },
    { "col-lg-12": src }
  );
};

export const contentClassesMobile= (contentPos,isRight)=>{
  return classnames(`hide-to-sm text-center text-${contentPos} split-box-content`,{'sb-content-right':!isRight},{'sb-content-left':isRight})
}

export const contentClassesPC= (contentPos,isRight)=>{
  return classnames(`hide-from-sm text-justify text-${contentPos} split-box-content`,{'sb-content-right':!isRight},{'sb-content-left':isRight})
}