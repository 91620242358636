// @flow
import React from "react";
import PropTypes from "prop-types";
import {getBodyContent} from '../common/util'
import { Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion";


type TAccordionType ={
  title:string,
  subtitle?:string,
  component?:Function
}

const Component = ({items}:any) => {
  return (
    <Accordion>
      {items && items.map((item,index)=>(
        <AccordionItem key={index}>
          <AccordionItemTitle>
            <h5 className="u-position-relative">{item.title}
            <div className="accordion__arrow"/>
            </h5>
            {item.subtitle && <div>{item.subtitle}</div>}
          </AccordionItemTitle>
          <AccordionItemBody>
            {getBodyContent(item.component())}
          </AccordionItemBody>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

Component.propTypes = {
  items:PropTypes.array
};

export default Component;
