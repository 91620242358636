//flow
import React from "react";
import PropTypes from "prop-types";

type Type={
  predicate:boolean,
  labelActive:string,
  labelInactive:string,
}

const Component = ({ predicate,labelActive,labelInactive }) => {
  return predicate ? (
    <span className="label label-success">{labelActive}</span>
  ) : (
    <span className="label  label-danger">{labelInactive}</span>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;