// @flow
import React from "react";
import Favorites from "../../../../tools/favorites";
import PropTypes from "prop-types";

const Component = ({ favCount, show }: any) => {
  return (
    show && (
        <li>
          <Favorites favCount={favCount} />
        </li>
    )
  );
};

Component.propTypes = {
  favCount: PropTypes.number,
  show: PropTypes.bool
};

Component.defaultProps = {
  show: true
};

export default Component;
