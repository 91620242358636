//flow
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
const LazyImage = lazy(() => import("../../../../cloudinary-image"));

const DEFAULT_CLOUDINARY_CONFIG = {
  crop: "pad",
  gravity: "center",
  background: "white",
  height: 350
};

type Type = {
  backgroundSize:any,
  src:string,
  cloudinary?:any
}

const Component = ({cloudinary,src,backgroundSize}:Type) => {
  return cloudinary ? (
    <Suspense fallback={<div>...loading</div>}>
      <LazyImage {...{ ...DEFAULT_CLOUDINARY_CONFIG, ...cloudinary }} />
    </Suspense>
  ) : (
    src && (
      <div
        className="gl-item-image  bg-image background-fifty-percent"
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: backgroundSize
        }}
      />
    )
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
