import React from 'react';
import commentBox from 'commentbox.io';

export default class CommentBox extends React.Component {

  componentDidMount() {
    const {projectId,commentId}=this.props;
    this.removeCommentBox = commentBox(projectId);
  }

  componentWillUnmount() {
    this.removeCommentBox();
  }

  render() {
    const {commentId} = this.props;
    return (
      <div className="commentbox" />
    );
  }
}