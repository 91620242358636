// @flow
import React from "react";
import Hits from "./components/custom-hits";
import List from "../../../../grid/list";
import { withHandlers, compose, defaultProps } from "recompose";
import RefinementFilters from "../filter";
import { CurrentRefinements } from "react-instantsearch-dom";
import ClearRefinements from './components/custom-clear-refinements'
const MODAL_ID = "modalId";

type Type = {
  cols: number,
  gutter: number,
  itemStyle: number,
  framed: boolean,
  overlay: string,
  showFilter: boolean,
  onItemReady?: Function,
  getModal?: Function,
  modalId?:string,
  getTools?: Function,
  isAuth?: any
};

const Component = ({
  cols,
  getModal,
  gutter,
  itemStyle,
  framed,
  overlay,
  onItemReady,
  showFilter,
  modalId,
  getTools
}: Type) => {
  return (
    <List
      getModal={getModal}
      showFilter={showFilter}
      cols={cols}
      gutter={gutter}
      framed={framed}
      overlay={overlay}
      modalId={modalId}
    >
      <div style={{ margin: "0 2% 1% 2%" }} className="row border-width-1">
        <ClearRefinements/>
        <CurrentRefinements />
      </div>
      <Hits
        getTools={getTools}
        itemStyle={itemStyle}
        onItemReady={onItemReady}
      />
    </List>
  );
};

export default compose(
  defaultProps({
    showFilter: true,
    modalId: MODAL_ID,
  }),
  withHandlers({
    getModal: ({ isAuth }) => () => (
      <RefinementFilters
        isAuth={isAuth}
        onRefine={() => {
          //$(`#${MODAL_ID}`).modal("hide");
        }}
      />
    )
  })
)(Component);
