//flow
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from 'gatsby';


const fbClasses = (classes: string = "btn-sm  btn-rounded-full") =>
  classnames("btn btn-social-min btn-facebook", classes);
const goolecClasses = (classes: string = "btn-sm btn-rounded-full") =>
  classnames("btn btn-social-min btn-google", classes);
const twitterClasses = (classes: string = "btn-sm btn-rounded-full") =>
  classnames("btn btn-social-min btn-twitter", classes);
const pinterestClasses = (classes: string = "btn-sm btn-rounded-full") =>
  classnames("btn btn-social-min btn-pinterest", classes);
const instagramClasses = (classes: string = "btn-sm btn-rounded-full") =>
  classnames("btn btn-social-min btn-instagram", classes);
const contactClassess = (classes: string = "btn-sm btn-rounded-full") =>
  classnames("btn btn-social-min ", classes);

const getTitle = (type, isFollow) =>
  isFollow ? `Follow on ${type}` : `Share on ${type}`;

type Type = {
  fb?: string,
  twitter?: string,
  google?: string,
  instagram?: string,
  pinterest?: string,
  contact?: string,
  isFollow: boolean,
  classes?: string,
  title?: string,
  target?: string,
  containerClass?: string,
  headerStyle?: string,
};

const Component = ({
  fb,
  twitter,
  google,
  instagram,
  pinterest,
  contact,
  isFollow,
  classes,
  title,
  target,
  containerClass,
  headerStyle
}: Type) => {
  return (
    <div className={containerClass}>
      <ul>
        {title && (
          <li className={headerStyle}>
            Share:
          </li>
        )}
        {fb && (
          <li>
            <a
              href={fb}
              className={fbClasses(classes)}
              title={getTitle("Facebook", isFollow)}
              target={target}
            >
              <i className="fab fa-facebook-f" />
            </a>
          </li>
        )}
        {twitter && (
          <li>
            <a
              href={twitter}
              className={twitterClasses(classes)}
              title={getTitle("Twitter", isFollow)}
              target={target}
            >
              <i className="fab fa-twitter" />
            </a>
          </li>
        )}
        {google && (
          <li>
            <a
              href={google}
              className={goolecClasses(classes)}
              title={getTitle("Google+", isFollow)}
            >
              <i className="fab fa-google-plus-g" />
            </a>
          </li>
        )}
        {pinterest && (
          <li>
            <a
              href={pinterest}
              className={pinterestClasses(classes)}
              title={getTitle("pInterest", isFollow)}
              target={target}
            >
              <i className="fab fa-pinterest-p" />
            </a>
          </li>
        )}
        {instagram && (
          <li>
            <a
              href={instagram}
              className={instagramClasses(classes)}
              title={getTitle("Instagram", isFollow)}
              target={target}
            >
              <i className="fab fa-instagram" />
            </a>
          </li>
        )}
        {contact && (
          <li>
            <Link
              to={contact}
              className={contactClassess(classes)}
              title="Drop me a line"
              target={target}
            >
              <i className="fas fa-envelope" />
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  target:'_blank',
  contact:'/contact',
  fb:'https://www.facebook.com/femiakinsanyaartcollection',
  twitter:'https://twitter.com/AkinsanyaArt',
  instagram:' https://www.instagram.com/faac2018/',
  pInterest: '#',
  containerClass:'social-buttons'
};

export default Component;
