import React from "react";
import PropTypes from "prop-types";
import noImage from '../../../../../../../../static/img/no-image.png'

const Component = ({ height }) => {
  return (
    <div
      className=' background-fifty-percent'
      style={{
        backgroundImage: `url(${noImage})`,
        height,
        backgroundSize: "contain"
      }}
    />
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
