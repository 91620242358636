// @flow
import React from "react";
import PropTypes from "prop-types";

type Type={
  tags?:Array<string>
}

const Component = ({tags}:Type) => {
  console.log(tags)
  return (
    <div className="blog-single-tags">
      <ul>
        <li><span>Tags:</span></li>
        {tags && tags.map((tag,key)=>(
          <li key={key}><a href="#">#{tag}</a></li>
          ))}
      </ul>
    </div>
  );
};

Component.propTypes = {
  tags:PropTypes.array
};
Component.defaultProps = {};

export default Component;
