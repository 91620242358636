// @flow
import type { IMenu } from "../../definitions";
import $ from "jquery";
import { prepareImage } from "../../util";
import { delay } from "lodash";
import classNames from "classnames";
import store from "../../stores";

const RESPONSIVE_HIDE_CLASS = "hide-to-sm";

const TRANSFORMER = "f_auto,q_auto/b_white,c_pad,dpr_auto,g_center,h_350/";
export const initAsyncImageLoad = (searchState: any) => {};

const signInClasses = isAuthenticated =>
  classNames(RESPONSIVE_HIDE_CLASS, { hide: isAuthenticated });
const signOutClasses = isAuthenticated =>
  classNames(RESPONSIVE_HIDE_CLASS, { hide: !isAuthenticated });

const children = {
  home: [
    { name: "The Collection", url: "#collection" },
    { name: "Traditional African Art", url: "#traditional" },
    { name: "Modern & Contemporary Art", url: "#modern" },
    { name: "Featured", url: "#featured" },
    { name: "Publications", url: "#publications" }
  ],
  about: [
    { name: "The Collector", url: "/about/#about-collector" },
    { name: "Traditional African Art", url: "/about/#about-traditional" },
    { name: "Modern & Contemporary Art", url: "/about/#about-modern" }
  ],
  collection: [
    { name: "Traditional African Art", url: "/collection/traditional" },
    { name: "Modern & Contemporary Art", url: "/collection/modern" },
    { name: "Featured", url: "/collection/featured" },
    { name: "Artists", url: "/artists" }
  ],
  more: [
    { name: "Publications", url: "/posts/filters/_category:publication" },
    { name: "News", url: "/posts/filters/_category:news" },
    { name: "Videos", url: "/videos" },
    { name: "Announcements", url: "/posts/filters/_category:announcement" }
  ],
  user: [
    {
      name: "Login",
      onClick: () => {
        store.uIStore.authenticate()
      },
      url: "#",
      classname: signInClasses(store.uIStore.user)
    },
    {
      name: "Logout",
      onClick: () => {
        store.uIStore.signout()
      },
      url: "#",
      classname: signOutClasses(store.uIStore.user)
    }
  ]
};

export const headerLinks: Array<IMenu> = [
  { name: "Home", url: "/", children: children.home },
  { name: "About", url: "/about", children: children.about },
  { name: "Collection", url: "/collection", children: children.collection },
  { name: "Blog", url: "/posts/filters/_category:blog" },
  { name: "Contact", url: "/contact" },
  { name: "More", url: "#", children: children.more, onClick:(e)=>e.preventDefault() },
  {
    name: "User",
    url: "#",
    children: children.user,
    classname: RESPONSIVE_HIDE_CLASS
  }
];

export const footerLinks: Array<IMenu> = [
  { name: "Home", url: "/" },
  { name: "About", url: "/about" },
  { name: "Collection", url: "/collection" },
  { name: "Blog", url: "/blog" },
  { name: "Contact", url: "/contact" }
];

export const init = () => {
  // ===============================
  // Page header
  // ===============================

  // if #page-header exist add class "page-header-on" to <body>.
  if ($("#page-header").length) {
    $("body").addClass("page-header-on");
  }

  // if page header contains background image add class "ph-image-on" to #page-header.
  if ($(".page-header-image").length) {
    $("#page-header").addClass("ph-image-on");
  }

  // if class "hide-ph-image" exist remove class "ph-image-on".
  if ($(".page-header-image").hasClass("hide-ph-image")) {
    $("#page-header").removeClass("ph-image-on");
  }

  // ==================================
  // Fade out element with page scroll
  // ==================================

  $(window).scroll(function() {
    $(".fade-out-scroll-1").css("opacity", 1 - $(window).scrollTop() / 150);
    $(".fade-out-scroll-2").css("opacity", 1 - $(window).scrollTop() / 250);
    $(".fade-out-scroll-3").css("opacity", 1 - $(window).scrollTop() / 350);
    $(".fade-out-scroll-4").css("opacity", 1 - $(window).scrollTop() / 450);
    $(".fade-out-scroll-5").css("opacity", 1 - $(window).scrollTop() / 550);
    $(".fade-out-scroll-6").css("opacity", 1 - $(window).scrollTop() / 650);
    $(".fade-out-scroll-7").css("opacity", 1 - $(window).scrollTop() / 750);
    $(".fade-out-scroll-8").css("opacity", 1 - $(window).scrollTop() / 850);
  });

  // ========================
  // Parallax effect
  // ========================

  $(window).scroll(function() {
    var plxbgScroll = $(this).scrollTop();

    // parallax - image background position
    // $('.parallax-bg-1').css('background-position','center '+ ((plxbgScroll * 0.1)) +'px');
    // $('.parallax-bg-2').css('background-position','center '+ ((plxbgScroll * 0.2)) +'px');
    // $('.parallax-bg-3').css('background-position','center '+ ((plxbgScroll * 0.3)) +'px');
    // $('.parallax-bg-4').css('background-position','center '+ ((plxbgScroll * 0.4)) +'px');
    // $('.parallax-bg-5').css('background-position','center '+ ((plxbgScroll * 0.5)) +'px');
    // $('.parallax-bg-6').css('background-position','center '+ ((plxbgScroll * 0.6)) +'px');
  });

  $(window).scroll(function() {
    var plxScroll = $(this).scrollTop();

    // parallax - transform
    $(".parallax-1").css(
      "transform",
      "translate3d(0, " + plxScroll * 0.1 + "px, 0)"
    );
    $(".parallax-2").css(
      "transform",
      "translate3d(0, " + plxScroll * 0.2 + "px, 0)"
    );
    $(".parallax-3").css(
      "transform",
      "translate3d(0, " + plxScroll * 0.3 + "px, 0)"
    );
    $(".parallax-4").css(
      "transform",
      "translate3d(0, " + plxScroll * 0.4 + "px, 0)"
    );
    $(".parallax-5").css(
      "transform",
      "translate3d(0, " + plxScroll * 0.5 + "px, 0)"
    );
    $(".parallax-6").css(
      "transform",
      "translate3d(0, " + plxScroll * 0.6 + "px, 0)"
    );
  });
};
