// @flow
import React,{ Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "gatsby";
import Modal from "../../modal";
import Heading from "../../heading";
import type { IMenuBase } from "../../../definitions";

const colAndGutterClassNames = (col, gutter) => {
  return classNames("isotope", `col-${col}`, `gutter-${gutter}`);
};

const myThemeClassNames = (framed:boolean,overlayStyle:string,gridStyle?:number=0) => {
  return classNames(
    "isotope-items-wrap",
    { "gli-frame": framed },
    `gli-${overlayStyle}`,
    `gli-alter-${gridStyle}`
  );
};

type Type = {
  cols: number,
  gutter: number,
  framed: boolean,
  overlay: string,
  children?: any,
  header?: string,
  subtitle?: string,
  subTitleLink?: IMenuBase,
  showFilter?: boolean,
  getModal?: Function,
  modalId: string,
  name?:string
};

const Subtitle = ({ subtitle, link }: { subtitle?: string, link?: IMenuBase }) => (
  <span>
    {subtitle}/<Link to={link && link.url || "#"}>{link && link.name}</Link>
  </span>
);

const Component = ({
  cols,
  gutter,
  framed,
  overlay,
  children,
  header,
  subtitle,
  subTitleLink,
  showFilter,
  getModal,
  modalId,
  name,
}: Type) => {
  const modalTarget = `#${modalId}`;
  return (
    <section  id={name}>
      {header && (
        <Heading heading={header} subtitle={<Subtitle subtitle={subtitle} link={subTitleLink} />} />
      )}
      <div className="isotope-wrap tt-wrap">
        <div className={colAndGutterClassNames(cols, gutter)}>
          {showFilter && (

            <div className=" isotope-top-content gallery-share-on">
                <a
                  href="#"
                  className="col-lg-3 gallery-share"
                  data-toggle="modal"
                  data-target={modalTarget}
                  title="Share this gallery"
                >
                  <i className="fas fa-filter" />
                </a>
              <Modal pos={"left"} id={modalId} title={"Custom Filters"}>
                {getModal && getModal()}
              </Modal>
            </div>
          )}
          <div className={myThemeClassNames(framed,overlay)}>
            <div className="grid-sizer" />
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

Component.propTypes = {
  cols: PropTypes.number,
  gutter: PropTypes.number,
  overlay: PropTypes.string,
  framed: PropTypes.bool
};

Component.defaultProps = {
  cols: 5,
  gutter: 3,
  overlay: "coloured",
  modalId: "faac-modal",
  framed: true
};

export default Component;
