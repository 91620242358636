//flow
import React, { useState, useEffect } from "react";
import store from "../stores";
import { autorun } from "mobx";
import PropTypes from "prop-types";

import { ROLES, userHasRole } from "../util";

const Component = (role: string = ROLES.any, predicate: boolean = true) => (
  WrappedComponent: any
) => props => {
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    autorun(() => {
      if (!store.uIStore.user) {
        setAuth(false);
      } else {
        (userHasRole(role) || role === ROLES.any) &&
          predicate &&
          setAuth(store.uIStore.user);
      }
    });
  });
  return <WrappedComponent isAuth={auth} {...props} />;
};

export default Component;
