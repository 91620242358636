// @flow
import React from "react";
import {
  Logo,
  CopyRights,
  Menu,
  Text
} from "./components";
import {SubscriptionForm} from '../forms'
import SocialFollow from '../tools/social-buttons'
import PropTypes from "prop-types";
import type { IMenu } from "../../definitions";
import classnames from "classnames";

type Type = {
  links: Array<IMenu>,
  isDark?: boolean,
  noMarginTop?: boolean,
};

const footerClassNames = (isDark, noMarginTop) =>
  classnames("hidden-print",{ "footer-dark": isDark }, { "no-margin-top": noMarginTop });

const Component = ({ links, isDark, noMarginTop }: Type) => {
  return (
    <section id="footer" className={footerClassNames(isDark,noMarginTop)}>
      <div className="footer-inner">
        <div className="footer-container tt-wrap">
          <div className="row">
            <div  className="col-lg-5 hide-from-sm">

                <Logo id='logo'/>

            </div>

            <div className="col-md-3">
              <Text />
            </div>

            <div className="col-md-4">
              <SocialFollow isFollow classes={'btn-default'} />
              <SubscriptionForm inputClasses='bg-none' />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-container tt-wrap">
            <div className="row">
              <div className="col-md-6 col-md-push-6">
                <Menu links={links} />
              </div>
              <div className="col-md-6 col-md-pull-6">
                <CopyRights />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Component.propTypes = {
  links: PropTypes.array,
  isDark: PropTypes.bool,
  noMarginTop: PropTypes.bool,
};

export default Component;
