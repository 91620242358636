// @flow
import React from "react";
import PropTypes from "prop-types";
import {Link} from 'gatsby'

const Component = ({publishedBy, publishedAt}:any) => {
  return (
    <div className="blog-single-meta-wrap">
      <Link to="" className="author-avatar pull-left bg-image"
         style={{backgroundImage: `url(/img/default-avatar.jpg)`}}/>
      <div className="blog-single-meta">
        <div className="article-author">by: <a href="#">{publishedBy}</a></div>
        <div className="article-time-cat">
          <span className="article-time">{publishedAt}</span>
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  publishedAt:PropTypes.string,
  publishedBy:PropTypes.string,
};
Component.defaultProps = {};

export default Component;
