// @flow
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { containerClass } from "./helpers";

type Type = {
  heading?: string,
  subtitle?: any,
  link?: string,
  top?: number,
  btm?: number,
  pos?: string,
  size?: string
};

const Component = ({ heading, subtitle, link, top, btm, pos, size }: Type) => {
  return (
    <div className={containerClass({ top, btm, pos, size })}>
      <div className="tt-heading-inner tt-wrap">
        <h1 className="tt-heading-title">{heading}</h1>
        <div className="tt-heading-subtitle">
          {subtitle}
        </div>
        <hr className="hr-short" />
      </div>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  top: 40,
  size: "sm",
  pos: "left"
};

export default Component;
