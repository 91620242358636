//flow
import React, { Fragment } from "react";
import { Grid } from "../..";
import { Link } from "gatsby";

import PropTypes from "prop-types";

type Type = {
  isPublished: boolean,
  isFeatured: boolean,
  isAuth: boolean,
  showDetail: boolean,
  hideImage: boolean,
  url:string,
};

const Component = ({
  isAuth,
  isPublished,
  showDetail,
  hideImage,
  isFeatured,
  url
}: Type) => {
  return isAuth ? (
    <Fragment>
      <li>
        <Link to={url || '#'}>
        <Grid.StatusTag
          predicate={showDetail}
          labelInactive={"Detail"}
          labelActive={"Detail"}
        />
        </Link>
      </li>
      <li>
        <Grid.StatusTag
          predicate={isFeatured}
          labelInactive={"F"}
          labelActive={"F"}
        />
      </li>
      <li>
        <Grid.StatusTag
          predicate={!hideImage}
          labelInactive={"I"}
          labelActive={"I"}
        />
      </li>
      <li>
        <Grid.StatusTag
          predicate={isPublished}
          labelInactive={"P"}
          labelActive={"P"}
        />
      </li>
    </Fragment>
  ) : null;
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
