import React, { Fragment } from "react";
import { capitalize } from "lodash";

import { prepareAccordion } from "./helpers";
import { MenuSelect, ToggleRefinement } from "react-instantsearch-dom";
import Accordion from "../../../../accordion";
import PropTypes from "prop-types";

const ADD_URL= "admin/#/collections/gallery/new"

const refinements = [
  { title: "Artist", props: { attribute: "artist", searchable: true } },
  {
    title: "Material/Medium",
    props: { attribute: "materialMedium", searchable: true }
  },
  {
    title: "Object Type",
    props: { attribute: "objectType", searchable: true }
  },
  { title: "Technique", props: { attribute: "technique" } },
  { title: "Country of Origin", props: { attribute: "originCountry" } }
];

const mappedLabels = {
  hideImage: "Image is Hidden",
  showDetail: "Can See Detail",
  isPublished: "Item is Published",
  isFeatured: "Item is Featured"
};

const AdminWidgets = ({ attributes }) => {
  return attributes.map(attribute => (
    <div className="margin-top-15">
      <span className="label label-info">
        {capitalize(mappedLabels[attribute])}:{" "}
      </span>
      <MenuSelect attribute={attribute} key={attribute} />
    </div>
  ));
};

const Component = ({ onRefine, isAuth }) => {
  return (
    <div>
      <Accordion items={prepareAccordion(refinements, onRefine)} />
      {isAuth && (
        <Fragment>
          <AdminWidgets
            attributes={["isPublished", "isFeatured", "showDetail", "hideImage"]}
          />
          <a target={'_blank'} href={ADD_URL} className="btn btn-primary-bordered">
            <i className="fa fa-plus"/>
            Add new
          </a>
        </Fragment>
      )}
    </div>
  );
};

Component.propTypes = {
  onRefine: PropTypes.func
};
Component.defaultProps = {};

export default Component;
