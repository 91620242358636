// @flow
import { airTableApi } from "../api";
import axios from "axios";
import type { IAirTableApi } from "../definitions";

const BASE_URL = process.env.FUNC_URL;

export default class AirTableApi implements IAirTableApi {
  async create(payload: any) {
    try {
      return axios.post(`${BASE_URL}/post-insert`, payload)
    } catch (err) {
      throw err;
    }
  }

  async getData(table: string, id: string) {
    try {
      return await airTableApi(table).find(id);
    } catch (err) {
      throw err;
    }
  }

  async list(table: string, options: Object = {}) {
    try {
      return await airTableApi(table)
        .select(options)
        .all();
    } catch (err) {
      throw err;
    }
  }

  async remove(id:string){
    try {
      return axios.get(`${BASE_URL}/post-remove`, {id})
    } catch (err) {
      throw err;
    }
  }


  async update(id: string, payload: Object) {
    try {
      return axios.post(`${BASE_URL}/post-update`, {id,payload})
    } catch (err) {
      throw err;
    }
  }
}

export const PostDataProvider = new AirTableApi();
