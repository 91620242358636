import React from "react";
import { Link } from "gatsby";
import { withHandlers } from "recompose";
import { Image, Transformation } from "cloudinary-react";
import PropTypes from "prop-types";

const ImageComponent = ({ publicId, crop, ...rest }) => (
  <Image
    publicId={publicId}
    responsive
    width="auto"
    crop={crop}
    effect="sharpen"
    quality="auto"
    fetchFormat="auto"
    {...rest}
  />
);

const Component = ({ publicId, onClick, crop, className, url, ...rest }) => {
  return (
    publicId &&
    (url ? (
      <Link onClick={onClick} to={url}>
        <div className={className}>
          <ImageComponent crop={crop} publicId={publicId} {...rest} />
        </div>
      </Link>
    ) : (
      <a href='#' onClick={onClick} className={className}>
        <ImageComponent crop={crop} publicId={publicId} {...rest} />
      </a>
    ))
  );
};

Component.propTypes = {
  cloudName: PropTypes.string,
  crop: PropTypes.string,
  publicId: PropTypes.string,
  url: PropTypes.string,
  apiKey: PropTypes.string,
  apiSecret: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  secure: PropTypes.bool
};
Component.defaultProps = {
  crop: "scale",
  secure: true
};

export default withHandlers({
  onClick: ({ publicId, onClick }: any) => (e) => {
    e.preventDefault();
    onClick && onClick(publicId);
  }
})(Component);
