// @flow
import React from "react";
import { Link } from "gatsby";
import {lowerCase} from 'lodash'
import PropTypes from "prop-types";
import classNames from "classnames";
import type { ICarouselCaption } from "../../../../../definitions";

const className = (
  vpos: string = "bottom",
  hpos: string = "left",
  size: string = "xxs"
) => {
  return classNames(
    "intro-caption",
    "caption-animate",
    `intro-caption-${size}`,
    `${vpos}-${hpos}`
  );
};


const Component = ({
  src,
  title,
  subtitle,
  description,
  size,
  vPOS,
  hPOS,
  actions
}: ICarouselCaption) => {
  return (
    <div className={className(vPOS, hPOS, size)}>
      <h2 className="intro-subtitle">{title}</h2>
      {subtitle && <h1 style={{fontWeight:'bolder'}} className="intro-title">{subtitle}</h1>}

      {description && (
        <p className="intro-description max-width-650">{description}</p>
      )}
      {actions && (
        <div className="margin-top-10">
          {Array.isArray(actions) &&
            actions.map(action => (
              <Link
                key={action.name}
                className={classNames(
                  "btn",
                  " margin-top-5",
                  "margin-right-10",
                  {
                    "btn-primary": action.isPrimary
                  },
                  {
                    "btn-white-bordered": !action.isPrimary
                  }

                )}
                to={action.url || "#"}
              >
                {action.name}
              </Link>
            ))}
        </div>
      )}
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string,
  vPOS: PropTypes.string,
  hPOS: PropTypes.string,
  actions: PropTypes.array
};

export default Component;
