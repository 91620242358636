//flow
import React from "react";
import PropTypes from "prop-types";
import { headerClasses } from "../helpers";

type Type ={
  headingSize:any,
  headingPos:any,
  paddingOn:any,
  title:string,
  subtitle:string,
}
const Component = ({headingSize,headingPos,paddingOn,title,subtitle}:Type) => {
  return (
    <div
      className={headerClasses(
        headingSize,
        headingPos,
        paddingOn
      )}
    >
      <div className="tt-heading-inner">
        <h1 className="tt-heading-title">{title}</h1>
        <div className="tt-heading-subtitle">{subtitle}</div>
        <hr className="hr-short" />
      </div>
    </div>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
