// @flow
import classNames from "classnames";
import moment from 'moment'
type GridType = {
  cols: number,
  gutter: number
};

type ItemType = {
  hasFrame: boolean,
  styleCode: number,
  hoverEffect: string
};

export const formatDate=(date:any)=>{
  return moment(date).format('MMM DD, YYYY')
}

export const getGridClassNames = ({ cols, gutter }: GridType) => {
  return classNames("isotope",`gutter-${gutter}`, `col-${cols}`);
};

export const getItemClassNames = ({ hoverEffect, hasFrame, styleCode }:ItemType) => {
  return classNames(
    "isotope-items-wrap",
    `gli-${hoverEffect}`,
    `gli-alter-${styleCode}`,
    { "gli-frame": hasFrame }
  );
};
