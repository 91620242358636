// @flow
import React from "react";
import PropTypes from "prop-types";
import withLifeCycle from '@hocs/with-lifecycle'
import Search from "./components/search";
import Languages from "./components/languages";
import {init} from'./js'

const Component = ({isDark,renderTools,showLanguageMenu}:any) => {
  return (
    <div className="tt-menu-tools">
      <ul>
        <Search/>
        {showLanguageMenu && <Languages isDark={isDark}/>}
        {renderTools && renderTools()}
      </ul>
    </div>
  );
};

Component.propTypes = {
  isDark:PropTypes.bool,
  showLanguageMenu:PropTypes.bool,
  renderTools:PropTypes.func,
};
Component.defaultProps = {};

export default withLifeCycle(
  {
    onDidMount(){
      init()
    }
  }
)(Component);
