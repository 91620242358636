// @flow
import React from "react";
import { bgClasses, captionClasses, pHClasses } from "./helpers";
import PropTypes from "prop-types";

type Type = {
  size?: string,
  pos?: string,
  captionSize?: string,
  parallaxbg?: number,
  bgFadeEffect?: number,
  hideImage?: boolean,
  src: string,
  parallax?: number,
  fadeEffect?: number,
  description?: string,
  title: string,
  wordSize?: number,
  children?:any,
  renderStats?:Function,
};

const Component = ({
  size,
  pos,
  parallaxbg,
  bgFadeEffect,
  hideImage,
  captionSize,
  src,
  parallax,
  fadeEffect,
  description,
  wordSize,
  title,
  children,
  renderStats,
}: Type) => {
  return (
    <section id="page-header" className={pHClasses(size, pos)}>
      <div
        className={bgClasses(parallaxbg, bgFadeEffect, hideImage)}
        style={{ backgroundImage: `url(${src})`, backgroundPosition:"center"}}
      >
        <div className="cover bg-transparent-4-dark" />
      </div>
      <div className="page-header-inner tt-wrap">
        <div className="row">
          <div className="col-lg-2">
            <div className={captionClasses(captionSize, parallax, fadeEffect)}>
              <h1 className="page-header-title">{title}</h1>
              <hr className="hr-short" />
              <div  className="page-header-description" data-max-words={wordSize}>
                <p>{description}</p>
                <span className='footer-text'>{renderStats && renderStats()}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-10 ">
            {children}
          </div>
        </div>

      </div>
    </section>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  captionSize: "xs",
  parallaxbg: 3,
  parralax: 5,
  fadeEffect: 3,
  wordSize: 40
};

export default Component;
