//flow
import React, { Fragment } from "react";
import { Link } from "gatsby";
import { observer } from "mobx-react";
import { compose, withProps, withHandlers } from "recompose";
import store from "../../stores";

import PropTypes from "prop-types";

type Type = {
  user?: { email: string },
  isAuthenticated?: boolean,
  authenticate?: Function,
  signout?: Function,
  register?: Function
};

const Component = ({
  authenticate,
  isAuthenticated,
  user,
  signout,
  register
}: Type) => {
  return (
    <Fragment>
      <li className="hide-from-sm">
        {!isAuthenticated ? (
          <Link to={"#"} onClick={authenticate} className="tt-tools-button">
            <i className="fa fa-user-lock" /> Login
          </Link>
        ) : (
          <Link to={"#"} onClick={signout} className="tt-tools-button">
            Welcome {user && user.user_metadata.full_name} | Signout
          </Link>
        )}
      </li>
      <li className="hide-from-sm">
        {!isAuthenticated && (
          <Link to={"#"} onClick={register} className="tt-tools-button">
            <i className="fa fa-user-lock" /> Register
          </Link>
        )}
      </li>
    </Fragment>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withHandlers({
    authenticate: () => e => {
      e.preventDefault();
      store.uIStore.authenticate();
    },
    register: () => (e) => {
      e.preventDefault();
      store.uIStore.authenticate("signup");
    },
    signout: () => (e) => {
      e.preventDefault();
      store.uIStore.signout();
    }
  }),
  observer,
  withProps(() => ({
    isAuthenticated: store.uIStore.isAuthenticated,
    user: store.uIStore.user
  }))
)(Component);
