// @flow
import React from "react";
import { withState, compose } from "recompose";
import withLifeCycle from "@hocs/with-lifecycle";
import Select from "react-select";
import PropTypes from "prop-types";
import type { IAirTableApi } from "../../../../../../definitions";
import { PostDataProvider } from "../../../../../../providers/air-table";
import { prepareDefaults } from "./helpers";
const SOURCE_TABLE = "Categories";

type Type = {
  data: Array<any>,
  defaultValue: Array<any>
};

const Component = ({ data, defaultValue, ...rest }: Type) => {
  return (
   data && data.length? <div className="form-group">
      <Select
        placeholder={"select category"}
        options={data}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>:null
  );
};

Component.propTypes = {
  provider: PropTypes.any,
  defaults: PropTypes.array
};
Component.defaultProps = {
  provider: PostDataProvider
};

export default compose(
  withState("data", "setData", []),
  withState("defaultValue", "setDefaults", []),
  withLifeCycle({
    async onDidMount({
      provider,
      setData,
      setDefaults,
      defaults
    }: {
      provider: IAirTableApi,
      setData: Function,
      setDefaults: Function,
      defaults: Array<string>
    }) {
      try {
        const records = await provider.list(SOURCE_TABLE);
        const result = records.map((record: { id: any, get: Function }) => ({
          value: record.id,
          label: record.get("name")
        }));
        if (result) {
          defaults && setDefaults(prepareDefaults(result, defaults));
          setData(result);
        }
      } catch (err) {
        console.error("failed to initialize data -", err);
      }
    }
  })
)(Component);
