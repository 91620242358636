import React, { lazy, Suspense, Fragment } from "react";
import { Link, navigate } from "gatsby";
import { isFunction, lowerCase } from "lodash";

import withLifeCycle from "@hocs/with-lifecycle";
import { FavTools, Info, ImageItem } from "./components";
import classNames from "classnames";
import PropTypes from "prop-types";
import { compose, defaultProps, withHandlers } from "recompose";
import noImage from '../../../../../static/img/no-image.png'

const NO_IMAGE_SRC = "./img/no-image.png";

const DEFAULT_TRANSLATIONS = {
  subtitle: payload => {
    return !payload || lowerCase(payload) === "unknown"
      ? "Artist Unknown"
      : payload;
  }
};

const className = style =>
  classNames("isotope-item", "outdoor", `iso-height-${style}`);

const getTranslation = (translations: any, payload: string, key: string) => {
  const translation = translations[key];

  if (translation) {
    return isFunction(translation) ? translation(payload) : translation;
  }
  return payload;
};

const Component = ({
  id,
  url,
  src,
  title,
  subtitle,
  itemStyle,
  imageId,
  backgroundSize,
  showFav,
  favCount,
  linkState,
  cloudinary,
  onClick,
  translations,
  hideImage,
  getTools,
  showDetail
}: any) => {
  const hashKey = url.split("/").pop();
  const _src = hideImage ? noImage : src;
  return (
    <div id={hashKey} className={className(itemStyle)}>
      <div className="gallery-list-item">
        <div className="gl-item-image-wrap">
          {showDetail ? (
            <Link
              onClick={onClick}
              state={linkState}
              to={url}
              className="gl-item-image-inner"
            >
              <ImageItem
                src={_src}
                backgroundSize={backgroundSize}
                cloudinary={cloudinary}
              />
              <span className="gl-item-image-zoom" />
            </Link>
          ) : (
            <Fragment>
              <ImageItem
                src={_src}
                backgroundSize={backgroundSize}
                cloudinary={cloudinary}
              />
              <span className="gl-item-image-zoom" />
            </Fragment>
          )}
          <ul className="gli-meta">
            {getTools && getTools()}
            {showFav && <FavTools favCount={favCount} show={showFav} />}
          </ul>
        </div>
        <Info
          showWithLink={showDetail}
          title={getTranslation(translations, title, "title")}
          subtitle={getTranslation(translations, subtitle, "subtitle")}
          url={url}
        />
      </div>
    </div>
  );
};

Component.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  getTools: PropTypes.func,
  subtitle: PropTypes.string,
  src: PropTypes.string,
  showFav: PropTypes.bool,
  favCount: PropTypes.number,
  onItemReady: PropTypes.func,
  itemStyle: PropTypes.number,
  navigateWithClick: PropTypes.bool,
  backgroundSize: PropTypes.string,
  cloudinary: PropTypes.object,
  linkState: PropTypes.object,
  hideImage: PropTypes.bool,
  translations: PropTypes.any,
  showDetail: PropTypes.bool
};

export default compose(
  defaultProps({
    itemStyle: "none",
    backgroundSize: "contain",
    translations: DEFAULT_TRANSLATIONS
  }),
  withHandlers({
    onClick: ({ navigateWithClick, linkState, url }: any) => e => {
      if (navigateWithClick) {
        e.preventDefault();
        navigate(url, {
          state: { ...linkState, scrollPos: document.documentElement.scrollTop }
        });
      }
    }
  }),
  withLifeCycle({
    onDidMount({
      url,
      imageId,
      title,
      subtitle,
      showFav,
      favCount,
      cloudinary,
      onItemReady
    }: any) {
      const item = { url, title, subtitle, showFav, favCount, imageId };
      onItemReady && onItemReady(item);
    }
  })
)(Component);
