const algoliasearch = require("algoliasearch");
const AirTable = require("airtable");

// ATApiKey=keyIlEVmx0iN6PYZW
const apiKey = 'keyIlEVmx0iN6PYZW';//process.env.ATApiKey;
const baseId = 'appTrzhZ9pouPZVad';//process.env.ATBlogBaseId;
const IndexName = process.env.ALG_INDEX_NAME;
const algApi= algoliasearch("P3NLE8O0I7", "4eff4d47837b7cef44e829ce63e8fe6f");

module.exports = {
  algApi,

  airTableApi: (tableName = "Posts") => {
    return new AirTable({apiKey}).base(baseId).table(tableName);
  },

  getPageStats: async filters => {
      return await algApi.initIndex(IndexName).search({
        filters: filters,
        attributesToRetrieve: null,
        attributesToHighlight: null
      });
  }
};
