// @flow
import React from "react";
import PropTypes from "prop-types";
import { Logo, Menu } from "./components";
import withLifeCycle from '@hocs/with-lifecycle'
import classNames from "classnames";
import {init} from './js'

const className = (transparent, position = "right") =>
  classNames("header-show-hide-on-scroll", `menu-align-${position}`, {
    "header-transparent": transparent
  },'hidden-print');

const Component = ({ menuLinks, isDark, transparent, position,renderTools,showLanguageMenu }: any) => {
  return (
    <header id="header" className={className(transparent, position)}>
      <div className="header-inner tt-wrap">
        <Logo />
        <Menu showLanguageMenu={showLanguageMenu} renderTools={renderTools} isDark={isDark} urlLinks={menuLinks} />
      </div>
    </header>
  );
};

Component.propTypes = {
  menuLinks: PropTypes.array,
  isDark: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  transparent: PropTypes.bool,
  position: PropTypes.string,
  renderTools: PropTypes.func,
};

export default withLifeCycle({
  onDidMount() {
    setTimeout(init,400)
  }
})(Component);
