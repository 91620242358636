// @flow
import React from "react";

import PropTypes from "prop-types";
import { ImageItem, NoImageItem } from "./components";

const IMAGE_HEIGHT = 650;

const Component = ({
  thumbs,
  title,
  artist,
  onError,
  hideImage,
  ...rest
}: any) => {
  return hideImage ? (
    <NoImageItem height={IMAGE_HEIGHT} />
  ) : thumbs ? (
    <ImageItem
      title={title}
      height={IMAGE_HEIGHT}
      thumbs={thumbs}
      artist={artist}
      {...rest}
    />
  ) : null;
};

Component.propTypes = {
  thumbs: PropTypes.array,
  title: PropTypes.string,
  artist: PropTypes.string,
  hideImage: PropTypes.bool
};

export default Component;
