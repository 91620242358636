import $ from 'jquery'
export const init =()=>{
  // ===================================================
  // Header
  // ===================================================

  // if #header contains class "header-transparent" add class "header-transparent-on" to <pody>.
  if ($('#header').hasClass('header-transparent')) {
    $('body').addClass('header-transparent-on');
  }


  // Hide header on scroll down, show on scroll up
  // More info: https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
  // ===================================================
  var didScroll;
  var lastScrollTop = 0;
  var delta = 120;
  var navbarHeight = $('.header-show-hide-on-scroll').outerHeight();

  $(window).scroll(function(event){
    didScroll = true;
  });

  setInterval(function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 50);

  function hasScrolled() {
    var st = $(window).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
      return;

    // If they scrolled down and are past the header, add class .fly-up.
    // This is necessary so you never see what is "behind" the header.
    if (st > lastScrollTop && st > navbarHeight){
      // Scroll Down
      $('.header-show-hide-on-scroll').addClass('fly-up');
    } else {
      // Scroll Up
      if(st + $(window).height() < $(document).height()) {
        $('.header-show-hide-on-scroll').removeClass('fly-up');
      }
    }

    lastScrollTop = st;
  }


  // Header Filled (cbpAnimatedHeader)
  // More info: http://tympanus.net/codrops/2013/06/06/on-scroll-animated-header/
  // ====================================
  var cbpAnimatedHeader = (function() {

    var docElem = document.documentElement,
      header = document.querySelector( '#header' ),
      didScroll = false,
      changeHeaderOn = 1;

    function init() {
      window.addEventListener( 'scroll', function( event ) {
        if( !didScroll ) {
          didScroll = true;
          setTimeout( scrollPage, 300 );
        }
      }, false );
    }

    function scrollPage() {
      var sy = scrollY();
      if ($(this).scrollTop() > 150){
        $('#header.header-fixed-top, #header.header-show-hide-on-scroll').addClass("header-filled");
      }
      else{
        $('#header.header-fixed-top, #header.header-show-hide-on-scroll').removeClass("header-filled");
      }
      didScroll = false;
    }

    function scrollY() {
      return window.pageYOffset || docElem.scrollTop;
    }

    init();

  })();


  // Set padding-top to <body> if needed
  // ====================================
  $(window).resize(function() {

    // Make <body> padding-top equal to "#header" height if "#header" contains one of these classes: "header-fixed-top", "header-show-hide-on-scroll".
    if ($('#header').is('.header-fixed-top, .header-show-hide-on-scroll')) {
      $('body').css( 'padding-top', $('#header').css('height'));
    }

    // Set "body" padding-top to "0" if "#header" contains class: "header-transparent".
    if ($('#header').is('.header-transparent')) {
      $('body').css('padding-top', 0);
    }

  }).resize();
}