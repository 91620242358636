//flow
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import axios from "axios";
import qs from "qs";
import Form from "./base-form";
const NAME = "faac-subscribe-form";

type Type = {
  ButtonContent?: any,
  formClasses?: string,
  inputClasses?: string,
  inputContainerClasses?:string,
  name?: string
};

const Component = ({ ButtonContent,inputClasses, formClasses, name,inputContainerClasses,...rest }: Type) => {
  const [email, setEmail] = useState(null);

  const onChange = e => {
    const value = e.target.value;
    setEmail(value);
  };

  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const data = { email,subject:'New Subscription', ...{ "form-name": form.getAttribute("name") } };
    const options = {
      method:'POST',
      url: '/',
      data: qs.stringify(data),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    axios(options).then(() => {
      alert("Thanks for subscribing with us, we will keep in touch");
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      name={name}
      id="footer-subscribe-form"
      className={formClasses}
      {...rest}
    >
      <div className={classnames("form-group ",inputContainerClasses)}>
        <input
          onChange={onChange}
          type="email"
          className={classnames("form-control", inputClasses)}
          id="footer-subscribe"
          name="subscribe"
          placeholder="Subscribe. Enter your email address..."
          required
        />
        {(ButtonContent && <ButtonContent />) || (
          <button type="submit">
            <i className="fas fa-paper-plane" />
          </button>
        )}
      </div>
    </Form>
  );
};

Component.propTypes = {};
Component.defaultProps = {
  name: NAME,
  formClasses: "form-btn-inside"
};

export default Component;
