// @flow
import React from "react";
import { MetaStats, MetaInfo, PostHeading, Tags, Share } from "./components";
import FavoriteBtn from "../../../tools/favorites";
import { Link } from "gatsby";
import AdminTools from "../../common/admin-tools";
import PropTypes from "prop-types";
import type { IBlog, IPost } from "../../../../definitions";
import { SocialButtons } from '../../../tools';
import {formatDate} from '../../helpers'


const Component = ({
  url,
  src,
  title,
  _category,
  publishedBy,
  lastPublishedAt,
  views,
  comments,
  body,
  _tags,
  favCount,
  editUrl,
  onPressRemove,
  isAuth,
  isPublished
}: IPost) => {
  return (
    <article className="blog-single-post">
      <Link to={url || "#"} className="blog-single-featured-image ">
        <img src={src} alt="" />
      </Link>
      <PostHeading title={title} category={_category} />

      <div className="blog-single-post-inner">
        <div className="blog-single-attributes">
          <div className="row">
            <div className="col-sm-8">
              <MetaInfo publishedAt={formatDate(lastPublishedAt)} publishedBy={publishedBy} />
            </div>

            <div className="col-sm-4">
              <ul className="blog-single-links list-unstyled list-inline">
                {views ||
                  (comments && <MetaStats comments={comments} views={views} />)}
                <AdminTools
                  isPublished={isPublished}
                  onPressRemove={onPressRemove}
                  classes="sm-scroll"
                  url={editUrl}
                  isAuth={isAuth}
                />
              </ul>
            </div>
          </div>
        </div>
        <div
          className="post-content text-justify"
          dangerouslySetInnerHTML={{ __html: body }}
        />
        <div className="blog-single-attributes margin-top-60">
          <div className="row">
            <div className="col-sm-8">
              <Tags tags={(_tags && _tags.split(",")) || []} />
            </div>
            <div className="col-sm-4 text-right">
              <ul className="blog-single-links list-unstyled list-inline">
                {/*<li>*/}
                  {/*<FavoriteBtn favCount={favCount} isActive />*/}
                {/*</li>*/}
                {/*<li>*/}
                  {/*<a*/}
                    {/*href="#post-comment-form"*/}
                    {/*className="leave-comment-btn sm-scroll"*/}
                  {/*>*/}
                    {/*Leave a Comment*/}
                  {/*</a>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
        <SocialButtons contact={null} title={'share:'} headerStyle='bss-text' containerClass='blog-single-share'/>
      </div>
    </article>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default Component;
